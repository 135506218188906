<template>
    <div class="transfer-modal" ref="transferModal" v-if="isShow">
        <div class="mask"></div>
        <div class="content">
            <div style="text-align:center"><i :class="data.icon" style="font-size:24px"></i></div>
            <div>
                <span>{{ data.msg  ? data.msg  : '转接中，请稍后...'}}</span>
                <span v-if="data.countdown != 0">{{ data.countdown }} 秒</span>
            </div>
        </div>
    </div>
</template>

<script>
import AppDialog from '@/components/Common/AppDialog.vue'

export default {
    props: {

    },
    components: {
        AppDialog
    },
    data() {
        return {
            baseCountdown: 0,
            data: {
                countdown: 30,
                icon: 'el-icon-loading'
            },
            isShow: false,
            timer: null
        };
    },

    methods: {
        openModal(countdown) {
            this.isShow = true;
            document.body.style.overflow = 'hidden';
            this.data.icon = 'el-icon-loading';
            this.data.msg = '';
            this.baseCountdown = countdown ?? 30
            this.data.countdown = countdown ?? 30
            this.startTimer();
        },
        setDataMsg(msg) {
            if (msg == '转接成功') {
                this.data.icon = 'el-icon-circle-check';
            } else {
                this.data.icon = 'el-icon-circle-close';
            }
            clearInterval(this.timer);
            this.data.msg = msg;
            this.data.countdown = 0;
            setTimeout(() => {
                this.closeModal();
            }, 3000);
        },
        closeModal() {
            this.isShow = false;
            document.body.style.overflow = 'auto';
        },
        startTimer() {
            this.timer = setInterval(() => {
                this.data.countdown--;
                if (this.data.countdown <= 0) {
                    this.data.countdown = 0;
                    clearInterval(this.timer);
                    this.setDataMsg(`${this.baseCountdown}s转接超时，请重新选择移交客服！`)
                    setTimeout(() => {
                        this.closeModal();
                    }, 3000);
                }
            }, 1000);
        }
    },
};
</script>
<style lang="scss" scoped>
.transfer-modal {
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .mask {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        z-index: 99999;
    }
    .content {
        > div:last-child {
            margin-top: 12px;
            font-size: 18px;
            > span:first-child {
                margin-right: 12px;
            }
        }
    }
}
</style>
