<template>
    <app-dialog :visible.sync="visible" title="转接提示" :show-footer="false">
        <div class="dialog__inner">
            <div style="text-align:center"><i class="el-icon-loading" style="font-size:24px; color: #0c8bfb;"></i></div>
            <p class="title">{{data.show_msg}}...{{ data.allocation_rules_wait_time }}s</p>
            <el-form label-width="120px" label-suffix="：">
                <el-row>
                    <el-col>
                        <el-form-item label="用户归属服务">{{ data.service_type_name }}</el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="转接备注">{{ data.remark }}</el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="同意/拒绝原因">
                            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入拒绝原因" v-model="reason">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div class="btn__group">
                <el-button type="danger" style="margin-right: 30px;" @click="handle(false)" :disabled="!reason">拒绝</el-button>
                <el-button type="primary" @click="handle(true)">同意</el-button>
            </div>
        </div>
    </app-dialog>
    <!--<div class="chat-receive-notification" ref="notification">-->
    <!--    <div>-->
    <!--        <div style="margin-top:12px">-->
    <!--            <i class="el-icon-loading" style="font-size:24px;margin-bottom:12px"></i>-->
    <!--        </div>-->
    <!--        <div>{{data.show_msg}}</div>-->
    <!--        <div style="margin-top:12px">-->
    <!--            <div style="text-align:left;margin-bottom:6px">拒绝原因：</div>-->
    <!--            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入拒绝原因" v-model="reason">-->
    <!--            </el-input>-->
    <!--        </div>-->
    <!--        <div class="btn">-->
    <!--            <el-button type="danger" @click="handle(false)" :disabled="!reason">拒绝</el-button>-->
    <!--            <el-button type="primary" @click="handle(true)">同意</el-button>-->
    <!--        </div>-->
    <!--    </div>-->
    <!--</div>-->
</template>

<script>
import { sendMessage } from '@/utils/websocket';
import AppDialog from '@/components/Common/AppDialog.vue'
export default {
    props: {

    },
    components: {
        AppDialog
    },
    data() {
        return {
            visible: false,
            data: {},
            reason: '',

            timer: null
        };
    },

    methods: {
        handle(status) {
            let message = {
                ChatMessageType: 602,
                ChatLogContent: {
                    text_content: { id: this.data.id, submit_flag: status, refuse_reason: this.reason },
                }
            };
            sendMessage(message)
            this.closeModal();
        },
        openModal(item) {
            this.reason = '';
            this.visible = true
            this.data = item;
            this.startTimer()
        },

        startTimer() {
            const allocation_rules_wait_time = +this.data.allocation_rules_wait_time
            if (allocation_rules_wait_time === 0) {
                clearTimeout(this.timer)
                this.timer = null
                this.closeModal()
                return
            }

            this.timer = setTimeout(() => {
                this.data.allocation_rules_wait_time -= 1
                this.startTimer()
            }, 1000)
        },

        closeModal() {
            this.visible = false
        }
    },
};
</script>
<style lang="scss" scoped>
    .title {
        margin: 10px 0;
        text-align: center;
        color: #0c8bfb;
    }

    .btn__group {
        text-align: center;
    }
//.chat-receive-notification {
//    transition: all 0.5s ease-out;
//    position: fixed;
//    right: 0px;
//    bottom: -2px;
//    width: 380px;
//    height: 0px;
//    max-height: 0;
//    z-index: 99999;
//    background: #fff;
//    border-radius: 6px 6px 0 0;
//    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1);
//    > div {
//        padding: 12px;
//        text-align: center;
//    }
//    .btn {
//        margin-top: 14px;
//        > * {
//            margin: 0 24px;
//        }
//    }
//}
</style>
