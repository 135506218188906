<template>
    <el-pagination background
                   @current-change="getData"
                   :current-page.sync="localCurrentPage"
                   :page-sizes="$attrs['page-sizes'] || [10, 20, 50, 100, 200]"
                   :page-size.sync="localPageSize"
                   :total="localTotal"
                   :layout="$attrs.layout || layout"
                   style="margin-top: 12px"
                   v-bind.sync="$attrs"
    >
    </el-pagination>
</template>

<script>
    export default {
        props: {
            options: {
                type: Object
            },
            total: {
                type: Number,
                default: 0
            },
            page: {
                type: Number,
                default: 1
            },
            rows: {
                type: Number,
                default: 10
            },
            limit: {
                type: Number,
                default: 10
            },
            layout: {
                type: String,
                default: 'total, sizes, prev, pager, next, jumper'
            }
        },

        data() {
            return {
                localCurrentPage: 1,
                localPageSize: 10,
                localTotal: 0
            }
        },

        watch: {
            total: {
                handler(val) {
                    this.localTotal = val
                },
                immediate: true
            },
            page: {
                handler(val) {
                    this.localCurrentPage = val
                }
            },
            rows: {
                handler(val) {
                    this.localPageSize  = val
                }
            },
            limit: {
                handler(val) {
                    this.localPageSize  = val
                }
            },
            localCurrentPage(val) {
                this.$emit('update:page', val)
            },
            localPageSize(val) {
                this.$emit('update:rows', val)
                this.$emit('update:limit', val)
                this.getData()
            }
        },

        methods: {
            getData() {
                this.$emit('getData')
            }
        }
    }
</script>

<style scoped>

</style>
