
<template>
    <div class="page-content">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
        }
    },
    created() { },
    mounted() { },
    methods: {},
    computed: {}
}
</script>
<style scoped lang='scss'>
</style>
