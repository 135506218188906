<template>
    <div style="height:100%">
        <el-tabs v-model="tabValue">
            <el-tab-pane label="用户信息" name="first">
                <div class="first">
                    <div>
                        <div>
                            <span>用户名称：</span>
                            <span>{{currentUser.senderName}}</span>
                        </div>
                        <div v-copy="{text: currentUser.senderName }">复制</div>
                    </div>
                    <div>
                        <div>
                            <span>用户ID：</span>
                            <!--{{ currentUser }}-->
                            <span>{{currentUser.user_id || currentUser.id}}</span>
                        </div>
                        <div v-copy="{text: currentUser.user_id || currentUser.id }">复制</div>
                    </div>

                    <div class="chat-history__wrap" v-if="currentWindow === 'current'">
                        <el-card shadow="never">
                            <div class="title">咨询记录</div>

                            <div class="chat-scroll_area" ref="chatRecordScroll">
                                <div style="display:flex; justify-content: center;">
                                    <template v-if="chatHistoryOptions.hasMore">
                                        <el-button type="text" :loading="chatHistoryOptions.isLoading" @click="loadMoreChatHistory">加载更多</el-button>
                                    </template>
                                    <template v-else>
                                        <el-button type="text" disabled>已经到顶了</el-button>
                                    </template>

                                    <!--<span style="cursor:pointer; margin: 8px 0 0;" v-loading="isLoadingRecord"></span>-->
                                </div>
                                <div class="message-list" ref="chatRecordList">
                                    <div class="message-item"
                                         v-for="(item, index) in chatHistoryOptions.list"
                                         :key="index"
                                         :class="[item.receiver_id === chatUserId ? 'right' : 'left']"
                                    >
                                        <div class="item-avatar">
                                            <el-avatar icon="el-icon-user-solid" :src="item.sender_img" size="small"></el-avatar>
                                        </div>
                                        <div class="item-end">
                                            <div class="item-nickname">{{ item.send_time }} {{ item.sender_name }}</div>
                                            <div class="item-content">
                                                <template v-if="item.send_content_type === 1">
                                                    <div class="item-msg__wrap">
                                                        <span v-html="item.send_content"></span>
                                                    </div>
                                                </template>
                                                <template v-else-if="item.send_content_type === 2">
                                                    <el-image style="height: 100px;max-width: 100px; object-fit: cover;" :src="item.send_content" :preview-src-list="[item.send_content]">
                                                    </el-image>
                                                </template>
                                                <template v-else-if="item.send_content_type === 5">
                                                    <div class="item-order__wrap">
                                                        <el-card shadow="never">
                                                            <el-form label-width="90px" label-suffix="：">
                                                                <el-row>
                                                                    <el-col :span="24">
                                                                        <el-form-item label="订单编号">
                                                                            <div style="text-align: left;"><span style="word-break: break-all">{{ item.send_content.cust_order_id }}</span></div>
                                                                        </el-form-item>
                                                                    </el-col>
                                                                    <el-col :span="24">
                                                                        <el-form-item label="订单状态">
                                                                            <div style="text-align: left;"><span style="word-break: break-all">{{ item.send_content.order_status }}</span></div>
                                                                        </el-form-item>
                                                                    </el-col>
                                                                    <el-col :span="24">
                                                                        <el-form-item label="下单时间">
                                                                            <div style="text-align: left;"><span style="word-break: break-all">{{ item.send_content.order_time }}</span></div>
                                                                        </el-form-item>
                                                                    </el-col>
                                                                    <el-col :span="24">
                                                                        <el-form-item label="订单金额">
                                                                            <div style="text-align: left;"><span style="word-break: break-all">{{ item.send_content.total_money }}</span></div>
                                                                        </el-form-item>
                                                                    </el-col>
                                                                    <el-col :span="24">
                                                                        <el-form-item label="打印文件">
                                                                            <div style="text-align: left;"><span style="word-break: break-all">{{ item.send_content.print_document_txt }}</span></div>
                                                                        </el-form-item>
                                                                    </el-col>
                                                                    <el-col :span="24">
                                                                        <el-form-item label="打印项目">
                                                                            <div style="text-align: left;"><span style="word-break: break-all">{{ item.send_content.public_model_name }}</span></div>
                                                                        </el-form-item>
                                                                    </el-col>
                                                                </el-row>
                                                            </el-form>
                                                        </el-card>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </div>

                    <!--{{ currentUser }}-->
                    <div class="history-data">
                        <template v-if="currentWindow === 'history'">
                            <el-card shadow="never" header="会话基础数据" style="width: 100%;">
                                <el-form label-width="110px" label-suffix="：">
                                    <el-row>
                                        <el-col>
                                            <el-form-item label="开始时间">{{ currentUser.in_time }}</el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item label="结束时间">{{ currentUser.session_end_time }}</el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item label="持续时长">{{ parseSecond2Str(currentUser.in_duration) }}</el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item label="平均响应时长">{{ parseSecond2Str(currentUser.average_reply_duration) }}</el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item label="首次响应时长">{{ parseSecond2Str(currentUser.first_reply_duration) }}</el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item label="顾客消息数">{{ currentUser.user_msg_num }}</el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item label="客服消息数">{{ currentUser.reply_msg_num }}</el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form>
                            </el-card>

                            <el-card shadow="never" header="会话评价" style="width: 100%; margin-top: 20px;">
                                <el-form label-width="110px" label-suffix="：">
                                    <el-row>
                                        <el-col>
                                            <el-form-item label="评价时间">{{ currentUser.evaluate_time }}</el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item label="评价">{{ ['未评价', '差评', '中评', '好评'][currentUser.evaluate_result] }}</el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item label="评价详情">{{ currentUser.evaluate_reason }}</el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form>
                            </el-card>
                        </template>
                    </div>

                    <!--<div v-show="showUserOrder" style="margin-top:20px;padding:0 12px;height:calc(100vh - 254px)" class="order">-->
                    <!--    <div class="title">订单信息</div>-->
                    <!--    <el-table :data="orderData" border style="width: 100%" v-loading="orderLoading" :height="orderData.length ? 'calc(100% - 74px)' : 'auto'">-->
                    <!--        <el-table-column prop="cust_order_id" label="订单号">-->
                    <!--        </el-table-column>-->
                    <!--        <el-table-column prop="order_time" label="下单时间"></el-table-column>-->
                    <!--        <el-table-column label="操作" width="60" align="center">-->
                    <!--            <template slot-scope="scope">-->
                    <!--                <el-button type="text" size="small">-->
                    <!--                    <div v-copy="{text: scope.row.cust_order_id }">复制</div>-->
                    <!--                </el-button>-->
                    <!--            </template>-->
                    <!--        </el-table-column>-->
                    <!--    </el-table>-->
                    <!--    <el-pagination style="margin-top:12px" v-if="orderData.length" small background @size-change="getOrderList" @current-change="getOrderList" :current-page="orderPageQuery.page" :page-size="orderPageQuery.limit" layout="prev, pager, next" :total="orderTotal">-->
                    <!--    </el-pagination>-->
                    <!--</div>-->
                </div>
            </el-tab-pane>

            <el-tab-pane label="常用回复" name="second" v-if="currentWindow === 'current'">
                <div class="second">
                    <div class="header">
                        <div class="header-btn__group">
                            <el-button icon="el-icon-plus" type="primary" @click="$refs.addUsefulExpressionsType.openModal()">添加分类</el-button>
                            <el-button icon="el-icon-plus" type="primary" @click="$refs.addShortcutMessage.openModal()">添加常用语</el-button>
                        </div>
                    </div>
                    <div class="content">
                        <el-tabs v-model="repliesQuery.type_id" editable @tab-click="(tab, e) => getChatCustomerCommonRepliesData(tab, e)" @edit="onRepliesTypeEdit">
                            <el-tab-pane v-for="item in repliesTypeData"
                                         :key="item.id"
                                         :label="item.type_name"
                                         :name="String(item.id)"
                            >
                                <div class="search-wrap">
                                    <el-input size="mini" v-model="replies" placeholder="模糊搜索常用语" prefix-icon="el-icon-search" style="width:calc(100% - 85px);margin-right:12px"></el-input>
                                    <el-button type="primary" size="mini" icon="el-icon-search" @click="searchReplies">搜索</el-button>
                                </div>

                                <div class="common-replies" v-loading="repliesLoading">
                                    <template v-if="isSearch ? searchRepliesData.length : repliesData.length">
                                        <div v-for="item in isSearch ? searchRepliesData : repliesData" :key="item.id">
                                            <span @click="sendMessage('edit', item)">{{ item.content }}</span>
                                            <span @click="deleteReplies(item)">
                                                <i class="el-icon-close"></i>
                                            <!--<el-popconfirm title="确定删除该条常用回复吗？" @confirm="deleteReplies(item)">-->

                                            <!--</el-popconfirm>-->
                                            </span>
                                        </div>
                                    </template>

                                    <span v-if="repliesLoading === false && isSearch ? searchRepliesData.length === 0 : repliesData.length === 0">暂无常用语</span>
                                </div>
                                <el-popover placement="right" title="常用回复" width="250" trigger="click" v-model="visible">
                                    <div>
                                        <el-input v-model="value" placeholder="常用回复"></el-input>
                                    </div>
                                    <div style="text-align: right; margin-top: 12px">
                                        <el-button size="mini" type="default" @click="visible = false">取消</el-button>
                                        <el-button type="primary" size="mini" @click="addReplies" :disabled="!value" v-no-more-click>确定</el-button>
                                    </div>
                                    <!--<a class="add" slot="reference">添加常用回复</a>-->
                                </el-popover>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </el-tab-pane>

            <el-tab-pane label="智能回复" name="third" v-if="currentWindow === 'current'">
                <div class="third">
                    <div class="header">
                        <div>
                            <el-select placeholder="问题分类" style="width:120px;margin-right:6px" size="mini" v-model="typeId" @change="getChatQuestionList">
                                <el-option :key="'#all#'" :label="'全部问题分类'" :value="'#all#'"></el-option>
                                <el-option v-for="item in questionType" :key="item.question_type_id" :label="item.type_name" :value="item.question_type_id"></el-option>
                            </el-select>
                            <!-- <sy-select placeholder="问题分类" style="width:120px;margin-right:6px" size="mini" v-model="typeId" @change="getChatQuestionList" :options="questionType" :props="{
                                label: 'type_name',
                                value: 'question_type_id'
                            }" /> -->
                            <el-input size="mini" v-model="question" placeholder="搜索相关内容" prefix-icon="el-icon-search" style="width:calc(100% - 126px)"></el-input>
                        </div>
                        <div class="btn">
                            <el-button type="primary" size="mini" icon="el-icon-search" @click="search()">搜索</el-button>
                            <el-button type="default" size="mini" icon="el-icon-refresh" @click="reset()">重置</el-button>
                        </div>
                    </div>
                    <div class="content" v-loading="loading">
                        <template v-for="(item, index) in questionList">
                            <div :key="index">
                                <div>
                                    <div>
                                        <span>问题分类：</span>
                                        <span>{{item.type_name}}</span>
                                    </div>
                                    <div>
                                        <span>问题问法：</span>
                                        <span>{{item.question}}</span>
                                    </div>
                                    <div>
                                        <span>问题答案：</span>
                                        <span>{{item.answer}}</span>
                                    </div>
                                </div>
                                <div>
                                    <div @click="sendMessage('send', item)">发送</div>
                                    <div @click="sendMessage('edit', item)">编辑发送</div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

        <add-useful-expressions-type ref="addUsefulExpressionsType" @refreshData="getChatCustomerCommonRepliesTypeData(false)"></add-useful-expressions-type>
        <add-shortcut-message ref="addShortcutMessage" @refreshData="getChatCustomerCommonRepliesData"></add-shortcut-message>
    </div>
</template>

<script>
    import AddUsefulExpressionsType from './AddUsefulExpressionsType.vue'
    import AddShortcutMessage from './AddShortcutMessage.vue'

    import {
        getChatQuestionTypeList,
        getChatQuestionList,
        getChatCustomerCommonRepliesData,
        addChatCustomerCommonReplies,
        delChatCustomerCommonReplies,
        getOrderPageList,

        getChatCustomerCommonRepliesTypeData, delChatCustomerCommonRepliesType,

        getChatHistoryDetail
    } from '@/api/phpApi/chatApiV2'

    export default {
        props: {
            currentUser: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            currentGroup: {
                type: Object,
                default: () => {
                    return {}
                }
            },

            currentWindow: {
                type: String,
                default: ''
            },
        },
        components: {
            AddUsefulExpressionsType,
            AddShortcutMessage
        },
        data() {
            return {
                visible: false,
                value: '',
                data: [],
                currentCommonreply: {},
                loading: false,
                orderLoading: false,
                repliesLoading: false,
                typeId: '#all#',
                question: '',
                replies: '',
                tabValue: 'first',
                questionType: [],
                questionList: [],

                repliesTypeData: [],
                repliesQuery: {
                    type_id: null
                },
                repliesData: [],

                isSearch: false,
                searchRepliesData: [],
                showUserOrder: false,
                orderData: [],
                orderTotal: 0,
                orderPageQuery: {
                    page: 1,
                    limit: 10,
                },

                chatUserId: null,

                chatHistoryOptions: {
                    list: [],
                    listQuery: {
                        page: 1,
                        limit: 10
                    },
                    total: 0,
                    isLoading: false,
                    hasMore: true,
                    isLock: false
                }
            };
        },

        methods: {
            parseSecond2Str(num) {
                if (!num) return '0秒'
                num = +num

                return `${~~(num / 60) <= 0 ? '' : ~~(num / 60) + '分'}${num % 60}秒`
            },

            async onChatHistoryScroll(e) {
                const scrollTop = e.target.scrollTop
                if (scrollTop <= 10) {
                    if (this.chatHistoryOptions.isLock) return
                    if (!this.chatHistoryOptions.hasMore) return

                    const wrapHeight = this.$refs.chatRecordList.clientHeight
                    await this.loadMoreChatHistory()
                    // console.log(this.$refs.chatRecordScroll.scrollTop)
                    this.$nextTick(() => {
                        this.$refs.chatRecordScroll.scrollTo({
                            top: this.$refs.chatRecordList.clientHeight - wrapHeight
                        })
                    })
                }
            },

            async loadMoreChatHistory() {
                if (this.chatHistoryOptions.isLock) return
                if (!this.chatHistoryOptions.hasMore) return

                this.chatHistoryOptions.listQuery.page += 1
                const wrapHeight = this.$refs.chatRecordList.clientHeight
                await this.getChatHistoryDetail()
                this.$nextTick(() => {
                    this.$refs.chatRecordScroll.scrollTo({
                        top: this.$refs.chatRecordList.clientHeight - wrapHeight
                    })
                })
            },

            async getChatHistoryDetail() {
                this.chatHistoryOptions.isLock = true
                this.chatHistoryOptions.isLoading = true
                const [ err, res ] = await getChatHistoryDetail({
                    source: this.currentGroup.source,
                    user_id: this.currentUser.user_id,
                    ...this.chatHistoryOptions.listQuery
                })
                this.chatHistoryOptions.isLock = false
                this.chatHistoryOptions.isLoading = false
                if (err) return
                this.chatHistoryOptions.total = res.data.count
                this.chatHistoryOptions.list = [
                    ...res.data.list.map(v => {
                        return {
                            ...v,
                            send_content: v.send_content_type === 5 ? JSON.parse(v.send_content) : v.send_content
                        }
                    }),
                    ...this.chatHistoryOptions.list
                ]
                this.chatHistoryOptions.hasMore = this.chatHistoryOptions.list.length < this.chatHistoryOptions.total
                this.chatUserId = res.data.user_id
                this.$nextTick(() => {
                    const element = document.querySelector('.message-list')
                    const scrollArea = document.querySelector('.chat-scroll_area')
                    scrollArea.scrollTop = element.scrollHeight
                })
            },

            async getOrderList() {
                this.tabValue = 'first';
                this.showUserOrder = true;
                this.orderLoading = true;
                const [err, res] = await getOrderPageList({
                    sys_user_id: this.currentUser.senderId,
                    source: this.currentGroup.source
                });
                if (err) return;
                this.orderLoading = false;
                this.orderData = res.data.list;
                this.orderTotal = res.data.count;
            },
            async getChatQuestionTypeList() {
                const [err, res] = await getChatQuestionTypeList({
                    page: 1,
                    limit: 1000,
                    source: this.currentGroup.source
                });
                if (err) return;
                this.questionType = res.data.list;
                this.typeId = '#all#';
                this.getChatQuestionList()
            },
            async getChatQuestionList() {
                this.loading = true;
                const [err, res] = await getChatQuestionList({
                    page: 1,
                    limit: 1000,
                    source: this.currentGroup.source,
                    question_type_id: this.typeId,
                    question: this.question
                });
                if (err) return;
                this.questionList = res.data.list.map(v => {
                    let type_name = this.questionType.find(item => item.question_type_id == v.question_type_id);
                    v.type_name = type_name ? type_name.type_name : v.question_type_id;
                    return v;
                });
                this.loading = false;
            },
            async getChatCustomerCommonRepliesData(tab = {}, e) {
                this.repliesLoading = true;
                const [err, res] = await getChatCustomerCommonRepliesData({
                    type_id: tab.name ?? this.repliesQuery.type_id
                });
                if (tab.name) {
                    this.replies = ''
                    this.isSearch = false
                }
                if (err) return;
                this.repliesData = res.data;
                this.repliesLoading = false;
            },
            reset() {
                this.question = '';
                this.getChatQuestionList();
            },
            // formatType(typeId) {
            //     if (typeId == '#all#') {

            //     } else {
            //         const type = this.questionType.find(v => v.question_type_id == typeId);
            //         return type ? type.type_name : typeId
            //     }
            // },
            search() {
                this.getChatQuestionList();
                // if (this.question == '') return data;
                // return data.filter(v => v.question.indexOf(this.question) != -1)
            },

            async getChatCustomerCommonRepliesTypeData(isSetId = true) {
                const [ err, res ] = await getChatCustomerCommonRepliesTypeData()
                if (err) return
                this.repliesTypeData = [
                    {
                        id: '0',
                        type_name: '默认库'
                    },
                    ...res.data
                ]

                if (isSetId) this.repliesQuery.type_id = this.repliesTypeData[0].id
            },
            searchReplies() {
                this.isSearch = true;
                this.searchRepliesData = this.repliesData.filter(v => v.content.indexOf(this.replies) != -1);
            },
            async addReplies() {
                const [err, res] = await addChatCustomerCommonReplies({
                    content: this.value
                });
                if (err) return;
                this.getChatCustomerCommonRepliesData();
                this.visible = false;
                this.value = null;
                this.$message.success('添加成功')
            },

            onRepliesTypeEdit(e, action) {
                if (action === 'remove') {
                    this.$alert('确定要删除此分类标签么，删除后连带该标签下的常用语会一并删除。', {
                        title: '确认删除',
                        type: 'warning',
                        showCancelButton: true,
                        callback: async (action, instance) => {
                            if (action === 'confirm') {
                                const [ err, res ] = await delChatCustomerCommonRepliesType({
                                    id: e
                                })
                                if (err) return
                                await this.getChatCustomerCommonRepliesTypeData(false)
                                if (+this.repliesQuery.type_id === +e) {
                                    this.repliesQuery.type_id = this.repliesTypeData[0].id
                                    this.getChatCustomerCommonRepliesData()
                                }
                            }
                        }
                    })
                }
            },
            async deleteReplies(dataRow) {
                this.$confirm('确定要删除此常用语么？', '确认删除', {
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    type: 'warning'
                }).then(async () => {
                    const [err, res] = await delChatCustomerCommonReplies({
                        id: dataRow.id
                    })
                    if (err) return
                    this.getChatCustomerCommonRepliesData()
                    this.$message.success(res.msg)
                }).catch(() => {})
            },

            sendMessage(type, item) {
                if (item.content) item.answer = item.content
                this.$emit('sendReply', {
                    type,
                    item
                })
            }
        },
        async mounted() {
            if (this.currentWindow === 'current') {
                this.getChatHistoryDetail()
                await this.getChatCustomerCommonRepliesTypeData()
                await this.getChatCustomerCommonRepliesData();
                await this.getChatQuestionTypeList()
            }

            this.$refs.chatRecordScroll.addEventListener('scroll', this.onChatHistoryScroll)
        },

        destroyed() {
            this.$refs.chatRecordScroll.removeEventListener('scroll', this.onChatHistoryScroll)
        }
    };
</script>
<style lang="scss" scoped>
    :deep(.el-tabs__nav-wrap::after) {
        height: 1px;
    }
    :deep(.el-tabs__nav-scroll) {
        display: flex;
        justify-content: center;
        margin-bottom: 0;
    }

    :deep(.el-tabs) {
        height: 100%;

        .el-tabs__content,
        .el-tab-pane,
        .first {
            height: 100%;
        }


    }

    .bottom {
        height: calc(100% - 68px);
    }
    .user-info {
        padding: 8px 12px;
        border-bottom: 1px solid #e8e8e8;
        width: calc(100% - 24px);
        .title {
            font-weight: bold;
            margin-bottom: 12px;
        }
        .info {
            > div {
                color: #666666;
                margin-bottom: 12px;
            }
            > div:last-child {
                margin-bottom: 6px;
            }
        }
    }
    .first {
        > div:not(.order):not(.history-data) {
            padding: 0 12px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            font-size: 13px;
            > div {
                color: #666666;
                white-space: nowrap;
                overflow: hidden;
                width: calc(100% - 30px);
                flex-shrink: 0;
                text-overflow: ellipsis;
            }
            > div:nth-child(2) {
                color: #409eff;
                cursor: pointer;
                user-select: none;
            }
        }
        .order {
            .title {
                font-size: 15px;
                margin-bottom: 6px;
                font-weight: bold;
            }
        }

        .chat-history__wrap {
            box-sizing: border-box;
            padding: 12px;
            height: 80%;

            :deep(.el-card) {
                width: 100%;
                background-color: transparent;

                .el-card__body {
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                    height: 100%;
                }
            }

            .title {
                position: relative;
                padding: 12px 20px;
                font-size: 16px;
                border-bottom: 1px solid #eee;

                &::before {
                    content: "";
                    position: absolute;
                    top: 14px;
                    left: 10px;
                    width: 4px;
                    height: 18px;
                    background-color: #0096ff;

                }
            }

            .chat-scroll_area {
                flex: 1;
                overflow-y: auto;
            }

            .message-list {
                display: grid;
                padding: 12px;

                .message-item {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 12px;

                    &.left {
                        .item-msg__wrap {
                            background-color: #fff;
                            color: #4a4a4a;
                            border: 1px solid #eaeaea;
                        }

                        .item-end {
                            margin-left: 8px;
                            //margin-top: -20px;
                        }
                    }

                    &.right {
                        flex-direction: row-reverse;

                        .item-nickname,
                        .item-content {
                            text-align: right;
                        }

                        .item-msg__wrap {
                            background-color: #0c8bfb;
                            color: #fff;
                        }

                        .item-end {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            margin-right: 8px;
                            //margin-top: -20px;
                        }
                    }

                    .item-avatar {
                        padding-top: 16px;

                        &,
                        img {
                            //width: 32px;
                            //height: 32px;
                            //border-radius: 50%;
                            //object-fit: cover;
                        }
                    }

                    .item-content {
                        margin-top: 4px;
                    }

                    .item-msg__wrap {
                        display: inline-flex;
                        padding: 4px 12px;
                        border-radius: 20px;

                        span {
                            word-break: break-all;
                            white-space: normal;
                        }
                    }

                    .item-order__wrap {
                        width: 250px;
                        margin-right: 8px;

                        span {
                            white-space: normal;
                        }

                        :deep(.el-card) {
                            padding: 4px;
                        }

                        :deep(.el-form-item) {
                            margin-bottom: 4px !important;
                        }
                    }
                }
            }
        }

        .history-data {
            padding: 0 12px;
        }
    }
    .third,
    .second {
        .header {
            padding: 0 12px 12px 12px;
            //text-align: right;
            border-bottom: 1px solid #e8e8e8;

            .btn {
                margin-top: 12px;
            }

            .header-btn__group {
                margin-bottom: 10px;
            }
        }

        .content {
            padding: 0 12px 12px 12px;

            :deep(#tab-0) {
                .el-icon-close {
                    display: none;
                }
            }

            :deep(.el-tabs__nav-scroll) {
                display: flex;
                justify-content: flex-start;
            }

            :deep(.el-tabs__new-tab) {
                display: none;
            }

            .search {
                text-align: right;
            }
        }
    }
    .second {
        .content {
            .add {
                display: block;
                text-align: left;
                padding: 0 12px;
                color: #409eff;
                margin-top: 12px;
                cursor: pointer;
            }
            .common-replies {
                max-height: calc(100vh - 270px);
                overflow: auto;
                > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 36px;
                    border-bottom: 1px solid #e8e8e8;
                    cursor: pointer;
                    padding: 0 12px;
                    user-select: none;
                    > span:nth-child(1) {
                        width: calc(100% - 16px);
                        height: 100%;
                        line-height: 36px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    i {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .third {
        .content {
            min-height: 200px;
            max-height: calc(100vh - 268px);
            overflow: auto;
            > div {
                background: #e8e8e8;
                margin-bottom: 4px;
                padding: 6px 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                > div:first-child {
                    > div {
                        display: flex;
                        margin: 4px 0;
                        > span:first-child {
                            flex-shrink: 0;
                            width: 60px;
                        }
                    }
                }
                > div:last-child {
                    flex-shrink: 0;
                    margin-left: 12px;
                    > div {
                        color: #409eff;
                        cursor: pointer;
                        margin: 12px 0;
                        text-align: center;
                    }
                }
            }
        }
    }
</style>
