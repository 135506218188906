<!--
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-10-20 14:37:36
 * @LastEditors: llg
 * @LastEditTime: 2022-10-25 14:48:10
-->
<template>
    <div class="order" v-if="visible">
        <div class="search">
            <el-form label-width="90px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="订单编号：">
                            <el-input v-model="listQuery.cust_order_id" placeholder="请输入订单编号搜索"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="下单时间：">
                            <el-date-picker v-model="dateTime"
                                            type="datetimerange"
                                            style="width: 100%;"
                                            start-placeholder="开始时间"
                                            end-placeholder="结束时间"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <div style="text-align: right;">
                            <el-button type="primary" @click="getOrderList">搜索</el-button>
                        </div>
                    </el-col>
                </el-row>
            </el-form>

        </div>

        <div class="list" v-loading="loading">
            <div class="item" v-for="item in data">
                <el-card shadow="never">
                    <div class="item-card">
                        <div class="left">
                            <el-checkbox v-model="item.isChecked" @change="e => onSelectOrderChange(item, e)"></el-checkbox>
                        </div>
                        <div class="center">
                            <el-form label-width="90px" label-suffix="：">
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item label="订单编号">
                                            <span style="word-break: break-all">{{ item.cust_order_id }}</span>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="订单状态">
                                            <span style="word-break: break-all">{{ item.order_status }}</span>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="下单时间">
                                            <span style="word-break: break-all">{{ item.order_time }}</span>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="订单金额">
                                            <span style="word-break: break-all">{{ item.total_money }}</span>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="打印文件">
                                            <span style="word-break: break-all">{{ item.print_document_txt }}</span>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="打印项目">
                                            <span style="word-break: break-all">{{ `${item.print_mode_names}-${item.print_set_names}` }}</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </div>
                        <div class="right">
                            <el-button type="text" @click="goDetail(item)">查看详情</el-button>
                        </div>
                    </div>
                    <!--{{ item.cust_order_id }}-->
                </el-card>
            </div>
        </div>

        <div class="pagination">
            <pagination :page.sync="listQuery.page" :rows.sync="listQuery.limit" :total="total" @getData="getOrderList" :page-sizes="[5, 10, 20, 50, 100, 200]"></pagination>
        </div>

        <div class="btn-group">
            <el-button style="margin-right: 40px;" @click="onCloseOrder()">关&nbsp;&nbsp;闭</el-button>
            <el-button type="primary" @click="onSendOrder()">发送订单</el-button>
        </div>
        <!--<el-dialog width="550px" :visible.sync="visible" title="订单" :modal-append-to-body='false' append-to-body>-->
        <!--    <el-table :data="data" border style="width: 100%" v-loading="loading" :height="'460px'">-->
        <!--        <el-table-column prop="custOrderId" label="订单号" width="300">-->
        <!--        </el-table-column>-->
        <!--        <el-table-column prop="orderTime" label="订单日期"></el-table-column>-->
        <!--    </el-table>-->
        <!--</el-dialog>-->
    </div>
</template>

<script>
    import Pagination from '@/components/Table/Pagination.vue'

    import { eventbus } from '@/utils/eventbus'
    import { sendMessage } from '@/utils/websocket'
    import { getOrderPageList } from '@/api/phpApi/chatApiV2'

    export default {
        props: {
            currentUser: {
                type: Object,
                default: () => {
                    return {}
                }
            },

            currentGroup: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },

        components: {
            Pagination
        },

        inject: ['messageClick'],

        data() {
            return {
                visible: false,
                loading: false,
                dateTime: [],
                listQuery: {
                    page: 1,
                    limit: 5,
                    cust_order_id: null,
                    order_start_time: null,
                    order_end_time: null
                },
                selectedOrderList: [],
                data: [],
                total: 0
            };
        },
        methods: {
            onSelectOrderChange(item, e) {
                const index = this.selectedOrderList.findIndex(v => v.order_id === item.order_id)
                if (index === -1) {
                    this.selectedOrderList.push(item)
                } else {
                    this.selectedOrderList.splice(index, 1)
                }
            },

            onSendOrder() {
                if (this.selectedOrderList.length === 0) {
                    this.$message.error('请至少选中一条订单')
                    return
                }

                if (this.currentUser.isEnd) {
                    this.$message.error('会话已断开，不能发送订单')
                    return
                }

                const list = this.selectedOrderList

                sendMessage({
                    ChatMessageType: 402,
                    ChatLogContent: {
                        text_content: '您好，请问想咨询如下哪笔订单呢？',
                        screen_type: 1
                    }
                })

                setTimeout(() => {
                    this.handleTaskSend(list)
                }, 3000)

                this.onCloseOrder()
            },

            handleTaskSend(list, index = 0) {
                if (!list[index]) return
                setTimeout(() => {
                    sendMessage({
                        ChatMessageType: 402,
                        ChatLogContent: {
                            text_content: {
                                cust_order_id: list[index].cust_order_id,
                                order_status: list[index].order_status,
                                order_time: list[index].order_time,
                                total_money: list[index].total_money,
                                print_document_txt: list[index].print_document_txt,
                                public_model_name: `${list[index].print_mode_names}-${list[index].print_set_names}`,
                            },
                            screen_type: 5
                        }
                    })

                    this.handleTaskSend(list, index + 1)
                }, 400)
            },

            onCloseOrder() {
                this.selectedOrderList = []
                this.visible = false
            },

            openModal(params) {
                this.visible = true;
                this.getOrderList(params);
            },

            async getOrderList() {
                this.loading = true;
                const [err, res] = await getOrderPageList({
                    sys_user_id: this.currentUser.senderId,
                    source: this.currentGroup.source,
                    ...this.listQuery
                });
                this.loading = false;
                if (err) return;
                this.data = res.data.list.map(v => {
                    return {
                        ...v,
                        isChecked: this.selectedOrderList.find(_v => _v.order_id === v.order_id) !== undefined
                    }
                })
                this.total = res.data.count;
            },

            goDetail(item) {
                this.messageClick()
                if (this.$route.name === 'admin.order.order') {
                    eventbus.$emit('chatJumpOrderList', item.cust_order_id)
                } else {
                    this.$router.push({
                        name: 'admin.order.order',
                        params: {
                            cust_order_id: item.cust_order_id
                        }
                    })
                }
            }
        },
        created() {
            this.getOrderList()
        },
    };
</script>

<style lang="scss" scoped>
    .order {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        background-color: #fff;
    }

    .search {
        padding: 12px;
    }

    .list {
        padding: 0 12px;
        margin-top: 20px;
        //padding-right: 12px;
        flex: 1;
        overflow-y: auto;

        .item {
            margin-bottom: 8px;

            .item-card {
                display: flex;
            }

            .left {
                padding-top: 5px;
            }

            .center {
                flex: 1;
                margin-left: 20px;
                margin-right: 20px;
            }
        }
    }

    .pagination {
        padding: 0 12px;
    }

    .btn-group {
        margin: 20px 0;
        display: flex;
        justify-content: center;
    }
</style>
