<template>
    <div class="chat-user" v-show="currentGroup.source">
        <el-tabs v-model="currentWindow" type="card">
            <el-tab-pane :label="`当前会话`" name="current">
                <div style="height: calc(100% - 39px);overflow: auto;">
                    <div class="search">
                        <el-input v-model="searchValue" @input="search" placeholder="搜索聊天记录" prefix-icon="el-icon-search" clearable :disabled="!currentGroup.children"></el-input>
                    </div>

                    <template v-if="(searchValue && userMessage) || currentGroup.children && currentGroup.children.length">
                        <div class="title">
                            <el-popconfirm title="确定将所有未读消息标记已读吗？" @confirm="clearUnRead()">
                                <el-button type="danger" icon="el-icon-delete" slot="reference">一键清除未读</el-button>
                            </el-popconfirm>

                            <!--<el-popover placement="top" width="160" v-model="visible">-->
                            <!--    <p>确定清除所有未读信息吗？</p>-->
                            <!--    <div style="text-align: right; margin: 0">-->
                            <!--        <el-button size="mini" type="default" @click="visible = false">取消</el-button>-->
                            <!--        <el-button type="danger" size="mini" @click="visible = false;clearUnRead()">清除</el-button>-->
                            <!--    </div>-->
                            <!--    <i class="el-icon-delete" slot="reference"></i>-->
                            <!--</el-popover>-->
                        </div>

                        <div class="chat-list" v-for="(item, index) in (searchValue != '' ? userMessage: currentGroup.children)" :key="index" :class="{'active': index == currentTab}" @click="selectUser(item, index)">
                            <div style="position: relative">
                                <div class="logo">
                                    <el-badge :value="item.unReadCount" :hidden="item.unReadCount == 0">
                                        <el-avatar icon="el-icon-user-solid" :src="item.SenderHeadImg || item.head_img"></el-avatar>
                                    </el-badge>
                                </div>
                                <div class="list">
                                    <div>
                                        <span>{{item.senderName}}</span>
                                        <div>
                                            <p v-if="item.isEnd" style="padding: 2px 4px; border: 1px solid #cda3f3; background-color: #f8eefe; color: #6727c6; position: absolute; top: 0; right: 0; border-radius: 4px">已结束</p>
                                            <p v-else-if="item.countdown <= 0 && !item.isEnd" style="padding: 2px 4px; background-color: #f00; color: #fff; position: absolute; top: 0; right: 0; border-radius: 4px">已超时</p>
                                            <span class="time">{{getLastUserMessage(item).time}}</span>
                                        </div>
                                    </div>
                                    <div style="position: relative;">
                                        <span>{{getLastUserMessage(item).content}}</span>
                                        <span style="position: absolute;right: 0;" v-if="!item.isEnd">
                                            <span style="color:red;font-weight:bold">{{item.countdown <= 0 ? '' : '倒计时：' + item.countdown }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <el-empty description="暂无会话"></el-empty>
                    </template>
                </div>
            </el-tab-pane>

            <el-tab-pane :label="`排队等待（${waitingData.wait_num}人）`" name="wait">
                <!--{{ waitingData }}-->
                <template v-if="waitingData.wait_session_list.length">
                    <div class="chat-list" v-for="(item, index) in waitingData.wait_session_list" :key="index" :class="{'active': index == currentTab}" @click="selectUser(item, index)">
                        <!--{{ item.user_name }}-->
                        <div>
                            <div class="logo">
                                <el-badge :value="item.unReadCount" :hidden="item.unReadCount == 0">
                                    <el-avatar icon="el-icon-user-solid" :src="item.head_img"></el-avatar>
                                </el-badge>
                            </div>
                            <div class="list">
                                <div>
                                    <span>{{item.user_name}}</span>
                                    <div>
                                        <!-- 售前 -->
                                        <p v-if="item.service_type === 'pre_sale'" style="padding: 2px 4px; border: 1px solid #aee88a; background-color: #f4ffeb; color: #47bd2d; position: absolute; top: 0; right: 0; border-radius: 4px">{{ item.service_type_name }}</p>
                                        <!-- 售中 -->
                                        <p v-else-if="item.service_type === 'on_sale'" style="padding: 2px 4px; border: 1px solid #7ce5d9; background-color: #e2fffa; color: #13bbba; position: absolute; top: 0; right: 0; border-radius: 4px">{{ item.service_type_name }}</p>
                                        <!-- 售后-->
                                        <p v-else-if="item.service_type === 'after_sale'" style="padding: 2px 4px; border: 1px solid #86d0fd; background-color: #e2f6ff; color: #1a85fa; position: absolute; top: 0; right: 0; border-radius: 4px">{{ item.service_type_name }}</p>
                                        <span class="time">{{ item.create_time }}</span>
                                    </div>
                                </div>
                                <div style="position: relative;">
                                    <span>{{ item.msg_list && item.msg_list[0] && item.msg_list[0].text_content }}</span>
                                        <span style="position: absolute;right: 0;">
                                        <span style="color:red;font-weight:bold">{{item.countdown <= 0 ? '已超时' : item.countdown }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <el-empty description="暂无排队用户"></el-empty>
                </template>
            </el-tab-pane>

            <el-tab-pane :label="`历史会话（${historySessionData.count}人）`" name="history">
                <div class="history__inner" v-loading="historySessionData.loading">
                    <div class="search">
                        <el-form>
                            <el-row>
                                <el-col>
                                    <el-form-item label="服务时间">
                                        <sy-date-picker type="date" :startTime.sync="historySessionParams.start_create_time" :endTime.sync="historySessionParams.end_create_time"></sy-date-picker>
                                        <div style="display: flex; justify-content: space-between; margin-top: 10px;">
                                            <div class="left">
                                                <el-button type="danger" icon="el-icon-delete" @click="delAllChatHistorySession()">全部删除</el-button>
                                                <el-button type="info" icon="el-icon-delete" @click="delChatHistorySession">批量删除</el-button>
                                            </div>
                                            <div class="right">
                                                <el-button type="primary" icon="el-icon-search" @click="historySessionData.list = []; historySessionParams.page = 1; getChatHistorySessionList()">搜索</el-button>
                                            </div>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>

                    <div class="scroll-area" style="position: relative; flex: 1; overflow-y: auto;" ref="historyListRef">
                        <div :style="{ height: historySessionData.list.length === 0 ? '100%' : 'auto' }" ref="scrollContentRef">
                            <template v-if="historySessionData.list.length">
                                <div class="chat-list" v-for="(item, index) in historySessionData.list" :key="index" :class="{'active': index == currentTab}" @click="selectUser(item, index)">
                                    <div>
                                        <div class="logo">
                                            <el-badge :value="item.unReadCount" :hidden="item.unReadCount == 0">
                                                <el-avatar icon="el-icon-user-solid" :src="item.head_img"></el-avatar>
                                            </el-badge>
                                        </div>
                                        <div class="list">
                                            <div>
                                                <span>{{item.user_name}}</span>
                                                <div>
                                                    <!-- 售前 -->
                                                    <p v-if="item.service_type === 'pre_sale'" style="padding: 2px 4px; border: 1px solid #aee88a; background-color: #f4ffeb; color: #47bd2d; position: absolute; bottom: 0; right: 0; border-radius: 4px">{{ item.service_type_name }}</p>
                                                    <!-- 售中 -->
                                                    <p v-else-if="item.service_type === 'on_sale'" style="padding: 2px 4px; border: 1px solid #7ce5d9; background-color: #e2fffa; color: #13bbba; position: absolute; bottom: 0; right: 0; border-radius: 4px">{{ item.service_type_name }}</p>
                                                    <!-- 售后-->
                                                    <p v-else-if="item.service_type === 'after_sale'" style="padding: 2px 4px; border: 1px solid #86d0fd; background-color: #e2f6ff; color: #1a85fa; position: absolute; bottom: 0; right: 0; border-radius: 4px">{{ item.service_type_name }}</p>
                                                    <span class="time">{{ item.create_time }}</span>
                                                </div>
                                            </div>
                                            <div style="position: relative;">
                                                <span>{{ item.send_content }}</span>
                                                <span style="position: absolute;right: 0;">
                                                    <span style="color:red;font-weight:bold">{{item.countdown <= 0 ? '已超时' : item.countdown }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item-checkbox" @click="onUserChecked">
                                        <el-checkbox v-model="item.isChecked"></el-checkbox>
                                    </div>
                                </div>

                                <p class="load-more" style="cursor:pointer;" v-if="hasMore && !isLoadingMore" @click="loadMore">加载更多</p>
                                <p class="load-more" v-else-if="isLoadingMore">
                                    加载中
                                    <i class="el-icon-loading"></i>
                                </p>
                                <p class="load-more" v-else-if="!hasMore">已经到底了</p>
                            </template>
                            <template v-else>
                                <el-empty description="暂无历史会话"></el-empty>
                            </template>
                        </div>
                    </div>


                </div>

            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>
import { sendMessage } from '@/utils/websocket';
import { getChatHistoryDetail } from '@/api/phpApi/chatApiV2'
import { getWaitCustomerDetail, getChatHistorySessionList, delChatHistorySession, delAllChatHistorySession } from '@/api/chatApi/index'

export default {
    props: {
        currentGroup: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    components: {
    },
    data() {
        return {
            currentWindow: 'current',

            currentTab: null,
            currentUser: {},
            visible: false,
            searchValue: '',
            userMessage: [],

            historySessionParams: {
                page: 1,
                limit: 10,
                start_create_time: null,
                end_create_time: null
            },

            waitingData: {
                wait_num: 0,
                wait_session_list: []
            },

            historySessionData: {
                loading: false,
                count: 0,
                list: []
            },

            isLoadingMore: false,
            hasMore: true,
            isLockLoadMore: false,
            scrollContentHeight: 0
        };
    },

    mounted() {
        this.$refs.historyListRef.addEventListener('scroll', e => {
            if (!this.hasMore || this.isLockLoadMore) return
            if (e.target.scrollTop + e.target.offsetHeight >= this.scrollContentHeight - 30) {
                this.loadMore()
            }
        })
    },

    methods: {
        async delChatHistorySession() {
            const ids = this.historySessionData.list.filter(v => v.isChecked).map(v => v.id)
            if (ids.length === 0) {
                this.$message.warning('请至少选择一条记录')
                return
            }

            this.$confirm('您确定要删除选中的记录吗？', {
                title: '温馨提示'
            }).then(async _ => {
                const [ err, res ] = await delChatHistorySession({
                    ids
                })
                if (err) return
                this.$message.success(res.msg)
                this.isLockLoadMore = false
                this.hasMore = true
                this.historySessionParams.page = 1
                this.getChatHistorySessionList(true)
            }).catch(_ => {})
        },

        async delAllChatHistorySession() {
            const [ err, res ] = await delAllChatHistorySession({
                source: this.currentGroup.source
            })
            if (err) return
            this.$message.success(res.msg)
            this.isLockLoadMore = false
            this.hasMore = true
            this.historySessionParams.page = 1
            this.getChatHistorySessionList(true)
        },

        async loadMore() {
            if (this.isLockLoadMore) return
            if (!this.hasMore) return
            this.historySessionParams.page += 1
            this.isLoadingMore = true
            await this.getChatHistorySessionList()
            this.isLoadingMore = false
        },

        async getChatHistorySessionList(isRefresh) {
            if (this.isLockLoadMore) return
            this.isLockLoadMore = true
            this.historySessionData.loading = true
            const [ err, res ] = await getChatHistorySessionList({
                source: this.currentGroup.source,
                ...this.historySessionParams
            })
            this.isLockLoadMore = false
            this.historySessionData.loading = false
            if (err) return
            this.historySessionData.count = res.data.count
            this.historySessionData.list = isRefresh
                    ? res.data.list.map(v => {
                        return {
                            ...v,
                            isChecked: false
                        }
                    })
                    : [
                        ...this.historySessionData.list,
                        ...res.data.list.map(v => {
                            return {
                                ...v,
                                isChecked: false
                            }
                        })
                    ]
            if (this.historySessionData.list.length === res.data.count) {
                this.hasMore = false
            }
            this.$nextTick(() => {
                this.scrollContentHeight = this.$refs.scrollContentRef.scrollHeight
            })
        },

        async getWaitCustomerDetail() {
            const [ err, res ] = await getWaitCustomerDetail()
            if (err) return
            this.waitingData = res.data.find(v => v.source === this.currentGroup.source).detail
        },

        onUserChecked(e) {
            e.stopPropagation()
        },

        async selectUser(user, index) {
            if (this.currentWindow === 'wait') {
                this.currentTab = index;
                this.currentUser = {
                    ...user,
                    children: user.msg_list.map(v => {
                        return {
                            ChatLogContent: {
                                img_url: v.screen_type === 1 ? '' : v.text_content,
                                link_url: '',
                                screen_type: v.screen_type,
                                text_content: v.text_content,
                            },
                            Source: v.source,
                            senderName: v.user_name,
                            position: 'left',
                            ChatMessageType: 300,
                        }
                    }),
                    countdown: 0,
                    hasCountDown: true,
                    isEnd: true,
                    senderId: user.sys_user_id,
                    senderName: user.user_name,
                    // 排队时长
                    waitTime: ~~this.calculateDifferenceInMinutes(+new Date(user.create_time), +new Date())
                };
                this.currentUser.unReadCount = 0;
            } else if (this.currentWindow === 'history') {
                this.currentTab = index;
                this.$nextTick(async () => {
                    this.$emit('onSelectUserBefore')
                    const [ err, res ] = await getChatHistoryDetail({
                        source: this.currentGroup.source,
                        user_id: user.user_id,
                        session_id: user.id,
                        limit: 99999
                    })
                    this.$emit('onSelectUserAfter')
                    if (err) return
                    this.currentUser = {
                        ...user,
                        children: res.data.list.reverse().map(v => {
                            return {
                                ChatLogContent: {
                                    img_url: v.send_content_type === 1 ? '' : v.send_content,
                                    link_url: '',
                                    screen_type: v.send_content_type,
                                    text_content: v.send_content,
                                },
                                Source: v.source,
                                senderName: v.receiver,
                                position: res.data.user_id === v.receiver_id ? 'left' : 'right',
                                ChatMessageType: 300,
                                ...v
                            }
                        }),
                        senderId: res.data.user_id,
                        // countdown: 0,
                        // hasCountDown: false,
                        senderName: user.user_name,
                        // // 排队时长
                        // waitTime: ~~this.calculateDifferenceInMinutes(+new Date(user.create_time), +new Date())
                        ...user
                    }

                    this.$store.commit('chat/setUserInfo', this.currentUser)
                    this.$emit('getCurrentUser', this.currentUser)
                })

            } else {
                this.currentTab = index;
                this.currentUser = user;
                this.currentUser.unReadCount = 0;
                sendMessage({
                    ChatMessageType: 423,
                })
            }

            console.log(this.currentUser)
            this.$store.commit('chat/setUserInfo', this.currentUser)
            this.$emit('getCurrentUser', this.currentUser)
        },

        calculateDifferenceInMinutes(timestamp1, timestamp2) {
            const millisecondsPerMinute = 1000 * 60;
            const differenceInMilliseconds = timestamp2 - timestamp1;
            return differenceInMilliseconds / millisecondsPerMinute;
        },

        getLastUserMessage(msg) {
            const { customerInfo } = this.$store.state.chat;
            const list = msg.children.filter(v => v.SenderId != customerInfo.SenderId);
            if (list.length) {
                const last = list[list.length - 1];
                return {
                    time: last.ChatLogSendTime,
                    content: last.ChatLogContent.screen_type == 2 ? '[图片]' : last.ChatLogContent.text_content,
                    countdown: last.countdown
                }
            }
            return {
                time: '',
                content: ''
            }
        },
        search() {
            if (this.searchValue == '') return;
            this.userMessage = [];
            this.currentGroup.children.forEach(item => {
                if (item.children && item.children.length) {
                    for (let i = 0; i < item.children.length; i++) {
                        if (item.children[i].ChatLogContent.text_content.indexOf(this.searchValue) > -1) {
                            this.userMessage.push(item)
                            break;
                        }
                    }
                }
            });
        },
        clearUnRead() {
            this.$emit('clearUnRead')
        }
    },
    watch: {
        currentGroup: {
            handler(val) {
                this.search()
                this.getWaitCustomerDetail()
                this.getChatHistorySessionList()
            }
        },

        currentWindow: {
            handler(val) {
                this.currentUser = {}
                this.$store.commit('chat/setUserInfo', this.currentUser)
                this.$emit('getCurrentUser', this.currentUser)
                this.currentTab = null
                if (val === 'wait') {
                    this.getWaitCustomerDetail()
                } else if (val === 'history') {
                    this.isLockLoadMore = false
                    this.hasMore = true
                    this.historySessionParams.page = 1
                    this.getChatHistorySessionList(true)
                }
                this.$emit('windowChange', val)
            },
            immediate: true
        }
    }
};
</script>
<style lang="scss" scoped>
    ::v-deep .el-avatar img {
        width: 100%;
    }

    :deep(.el-tabs),
    :deep(.el-tabs__content),
    :deep(.el-tab-pane),
    :deep(.el-empty) {
        height: 100%;
    }

    .el-empty {
        margin-top: -115px;
    }

    .chat-user {
        width: 22%;
        display: inline-block;
        border-right: 1px solid #e8e8e8;
        background: #fff;
        box-shadow: 0px 0px 7px 3px rgba(62, 62, 62, 0.05);
        user-select: none;
        :deep(.el-badge__content) {
            height: 16px;
            line-height: 16px;
            background: red;
            padding: 0 5px;
            border: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        :deep(.el-tabs__header) {
            margin-bottom: 0;
        }

        :deep(.el-tabs__nav) {
            width: 100%;

            .el-tabs__item {
                width: 33%;
                text-align: center;
                overflow: hidden;
            }
        }

        .search {
            padding: 6px;
            border-bottom: 1px solid #ddd;
        }

        .history__inner {
            height: calc(100% - 44px);
            display: flex;
            flex-direction: column;
            overflow-y: auto;
        }

        .title {
            border-bottom: 1px solid #e8e8e8;
            height: 38px;
            display: flex;
            justify-content: flex-end;
            padding: 6px 12px;
            align-items: center;

            i {
                font-size: 16px;
                cursor: pointer;
            }
        }
        .chat-list {
            position: relative;
            display: flex;
            align-items: center;
            &.active {
                background: #a8f0ff;
            }

            .item-checkbox {
                position: absolute;
                top: 4px;
                right: 4px;
            }

            > div:not(.item-checkbox) {
                display: flex;
                align-items: center;
                width: calc(100% - 12px);
                height: 64px;
                border-bottom: 1px solid #e8e8e8;
                padding: 6px;
                .logo {
                    width: 36px;
                    height: 36px;
                    padding: 0 12px 0 6px;
                    :deep(.el-avatar) {
                        width: 36px;
                        height: 36px;
                        line-height: 36px;
                    }
                }
                .list {
                    cursor: pointer;
                    width: calc(100% - 60px);
                    padding-right: 4px;
                    > div {
                        display: flex;
                        justify-content: space-between;
                        color: #696969;
                        height: 16px;
                        line-height: 16px;
                    }
                    > div:nth-child(1) {
                        margin-bottom: 4px;
                    }
                    > div:nth-child(2) {
                        span:nth-child(1) {
                            max-width: 240px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                    }
                    .time {
                        color: #c8c8c8;
                        text-align: right;
                    }
                }
            }
        }

        .load-more {
            margin: 8px 0;
            text-align: center;
        }
    }
</style>
