import Vue from "vue";
import store from '@/store/index'
import router from '@/router/index'

export default function componentFactory(component, containerId) {
    let childTemplate = Vue.extend(component);
    return new childTemplate({
        store,
        router
    }).$mount(containerId);
}
