<template>
    <el-dialog :visible.sync="visible"
               :width="width"
               :title="title"
               :fullscreen="fullscreen"
               append-to-body
               :before-close="beforeClose"
               @closed="onClosed"
               v-bind="$attrs"
               :class="{
                   'is-limit-height': !fullscreen
               }"
    >
        <slot name="title"></slot>
        <slot name="default"></slot>
        <span slot="footer" class="dialog-footer" v-if="(showCancel || showConfirm )&& showFooter">
            <slot name="footer">
                <el-button @click="cancel()" v-if="showCancel">{{ cancelText }}</el-button>
                <el-button :loading="isConfirmLoading || isLoading" type="primary" @click="confirm()" v-if="showConfirm">{{ confirmText }}</el-button>
            </slot>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: '提示'
            },
            width: {
                type: Number | String,
                default: '40%'
            },
            showCancel: {
                type: Boolean,
                default: true
            },
            showConfirm: {
                type: Boolean,
                default: true
            },
            showFooter: {
                type: Boolean,
                default: true
            },
            cancelText: {
                type: String,
                default: '取 消'
            },

            confirmText: {
                type: String,
                default: '确 定'
            },

            isCustomClose: {
                type: Boolean,
                default: false
            },

            fullscreen: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isConfirmLoading: false,
                isLoading: false
            }
        },

        methods: {
            emitEvent(e) {
                this.$emit('update:visible', e)
            },

            cancel() {
                if (!this.isCustomClose) {
                    this.$emit('update:visible', false)
                }
                this.$emit('cancel')
            },

            confirm() {
                this.$emit('confirm')
            },

            beforeClose() {
                if (this.isCustomClose) {
                    this.$emit('beforeClose')
                    return
                }
                this.emitEvent(false)
                return
            },

            onClosed() {
                this.$nextTick(() => {
                    this.$emit('closed')
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .is-limit-height {
        ::v-deep .el-dialog {
            max-height: 70vh;
        }
    }

    ::v-deep .el-dialog {
        display: flex;
        flex-direction: column;
    }

    ::v-deep .el-dialog__header {
        //position: absolute;
        //top: 0;
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        z-index: 2;
    }

    ::v-deep .el-dialog__body {
        flex: 1;
        box-sizing: border-box;
        overflow-y: auto;
    }
</style>
