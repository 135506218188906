<template>
    <div>
        <transition-group name="fade-list" mode="out-in">
            <div class="cart-controller" v-show="!isMinimize" key="box3">
                <div class="window-top_left">
                    <div class="text">
                        当前正在接待服务组：<span>{{ customerInfo.customer_group_num || 0 }}</span> 个；当前会话中：<span>{{ customerInfo.session_user_num || 0 }}</span> 人；当前排队等待：<span>{{ customerInfo.wait_num || 0 }}</span> 人
                    </div>
                    <el-button :loading="isLoadingCustomerInfo" type="primary" icon="el-icon-refresh" style="margin-left: 15px;" @click="getNowCustomerInfo">刷新</el-button>
                </div>
                <div style="position: absolute;right: 10px;top: 7px;font-size: 24px;cursor: pointer;">
                    <el-select v-model="statusId" @change="changeMyServiceStatus" placeholder="请选择状态" size="mini" style="width: 120px;vertical-align: text-bottom;margin-right: 6px;">
                        <el-option :label="item.service_name" :value="item.id" v-for="item in statusOptions" :key="item.id"></el-option>
                    </el-select>
                    <i class="el-icon-remove-outline" @click="messageClick"></i>
                </div>
                <div class="box">
                    <div class="reconnect-wrap" v-if="isReconnecting">
                    </div>

                    <chat-group @groupChange="groupChange" :sourceList="sourceList" :chatlogs="chatlogs" ref="chatGroup" />
                    <chat-user :currentGroup="currentGroup"
                               @getCurrentUser="getCurrentUser"
                               @clearUnRead="clearUnRead"
                               @windowChange="e => { currentWindow = e; currentUser = {} }"
                               ref="chatUser"
                               @onSelectUserBefore="e => onSelectUserBefore()"
                               @onSelectUserAfter="e => onSelectUserAfter()"
                    />
                    <div class="chat">
                        <transition name="fade" mode="out-in">
                            <div key="box1" style="height: 100%;" v-loading="loading" v-if="currentUser.senderId">
                                <div class="top">
                                    <chat-header :currentGroup="currentGroup" :currentUser="currentUser" :currentWindow="currentWindow" :sourceList="sourceList" :myInfo="myInfo" />
                                </div>
                                <div class="bottom">
                                    <div class="left">
                                        <chat-message ref="chatMessage" :myInfo="myInfo" :currentUser="currentUser" :currentGroup="currentGroup" :currentWindow="currentWindow" @getOrderList="getOrderList" />
                                    </div>
                                    <div class="right">
                                        <chat-opera :currentGroup="currentGroup" :currentUser="currentUser" :currentWindow="currentWindow" @sendReply="sendReply" ref="opera" />
                                    </div>
                                </div>
                            </div>
                            <div class="bottom" v-else style="justify-content: center;" key="box2">
                                <el-empty description="暂无消息" style="margin-top: -100px;"></el-empty>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
            <div v-show="isMinimize" class="minimize" @click="messageClick" key="box4" :class="{'flashing': newMessage}" @mousedown="mousedown" :style="{top: messageTop}">
                客服(PC端)
            </div>
        </transition-group>
        <audio :src="audioSrc" id="imNewAudio" @ended="overAudio"></audio>
        <receive-notification ref="receiveNotification" />
        <transfer-modal ref="transferModal" />
    </div>
</template>

<script>
import { ChatGroup, ChatUser, ChatOpera, ChatMessage, ChatHeader } from './components';
import { getPrintSourceList, getMySelfChatCustomerUserInfo, changeMySelfChatCustomerUserService, getChatCustomerUserServiceList, getNowCustomerInfo } from '@/api/phpApi/chatApiV2';
import { getWaitCustomerDetail } from '@/api/chatApi/index'
import { webSocketInit, onMessage, sendMessage, closeWs } from '@/utils/websocket';
import { Notification } from 'element-ui';
import componentFactory from "@/utils/componentFactory";
import ReceiveNotification from './components/transfer/ReceiveNotification';
import TransferModal from './components/transfer/TransferModal';
import router from '@/router';
// import { printsourcelist, chatlog, chatlogpage, statuslabel, changeChatUser, transfer, canchat } from '@/api/chatApi';
// import axios from 'axios';

import { Loading } from 'element-ui'

export default {
    components: {
        ChatGroup,
        ChatUser,
        ChatOpera,
        ChatMessage,
        ChatHeader,
        ReceiveNotification,
        TransferModal,
    },
    data() {
        return {
            sourceList: [],
            // chatlogs: [],

            currentUser: {},
            ststusOptions: [],
            statusId: '',
            currentStatus: null,
            transferMessage: [],
            canchat: true,
            isMinimize: true,
            newMessage: false,
            loading: false,
            // 是否重新连接
            isReconnecting: false,
            timer: null,
            audioSrc: require('@/assets/audio/imMessage.mp3'),
            closeTimer: null,
            canClick: true,
            messageTop: "calc(80vh - 40px)",
            //
            myInfo: {},
            chatlogs: [],
            messageList: [],
            userMessage: [],
            currentGroup: {},
            playNum: 0,
            statusOptions: [],
            config: {},

            currentWindow: 'current',
            customerInfo: {},
            isLoadingCustomerInfo: false,

            messageNoticeQueue: [],
        };
    },

    provide() {
        return {
            messageClick: this.messageClick,

            onTransferChange: this.onTransferChange
        }
    },

    mounted() {
        // this.init()
    },

    methods: {
        onTransferChange(options = {}) {
            console.log(options)
            const { type, data } = options
            if (type === 'open') {
                this.$refs.transferModal.openModal(data.allocation_rules_wait_time)
            }
        },

        async init() {
            // setTimeout(() => {
            //     receiveNotification.closeModal();
            // }, 4000)
            this.getNowCustomerInfo()
            this.getWaitCustomerDetail()
            await this.getServiceStatus()
            await this.myChatUserInfo();
            await this.getSourcelist();
            await this.initWebsocket();
        },

        async initWebsocket(isReconnect) {
            let loading = null

            if (isReconnect) {
                loading = Loading.service({
                    target: '.reconnect-wrap',
                    lock: true,
                    text: '服务重新连接中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, .7)'
                })
            }
            webSocketInit(_ => {
                console.log('连接成功');
                setTimeout(() => {
                    loading?.close()
                }, 500)
                this.isReconnecting = false
                onMessage(msg => {
                    this.wsOnMessage(msg);
                })
            }, {
                // 重连失败
                fail: () => {
                    loading?.close()
                    // 将在线状态改为离线
                    this.changeMyServiceStatus(2)
                    this.statusId = 2
                }
            })
        },

        async getWaitCustomerDetail() {
            const [ err, res ] = await getWaitCustomerDetail()
            if (err) return

            for (const item of res.data) {
                const sourceItem = this.chatlogs.find(v => v.source === item.source)

                if (!sourceItem) {
                    this.chatlogs.push({
                        source: item.source,
                        unReadCount: 0,
                        children: []
                    })
                }
            }
        },

        countdown(message) {
            message.hasCountDown = true;
            message.timer = setTimeout(() => {
                message.countdown--;
                if (message.countdown <= 0) {
                    message.countdown = 0;
                    clearTimeout(message.timer);
                } else {
                    this.countdown(message);
                }
            }, 1000)
        },
        wsOnMessage(message) {
            let chatLogContent = message.ChatLogContent;
            const chatMessageType = message.ChatMessageType;
            if (chatMessageType == 400 || chatMessageType == 606) {
                message.position = 'center';
            } else if (this.myInfo.SenderId == message.SenderId) {
                message.position = 'right';
            } else {
                message.position = 'left';
            }
            switch (chatMessageType) {
                // 获取当前客服数据
                case 200:
                    this.getMyInfo(chatLogContent);
                    break;
                // 接收消息
                case 402:
                case 400:
                    this.receiveMessage(message, 'new')
                    break;
                // 结束对话
                case 403:
                case 404:
                    this.endSession(message)
                    break;
                // 客服回复超时，自动结束
                case 411:
                    this.endSession(message)
                    break;
                // 用户回复超时，自动结束
                case 412:
                    this.endSession(message)
                    break;
                // 未读消息处理
                case 420:
                    this.getLogs(chatLogContent)
                    break;
                case 424:
                    this.config = chatLogContent.text_content;
                    break;
                // 非客服角色关闭连接不再重连
                case 501:
                    closeWs()
                    break;
                // 接收转接提醒
                case 601:
                    this.$nextTick(() => {
                        this.$refs.receiveNotification.openModal(chatLogContent)
                    })
                    break;
                // 处理转接
                case 602:
                    break;
                // 通知客服转接状态
                case 603:
                    this.$nextTick(() => {
                        this.$refs.transferModal.setDataMsg(chatLogContent.text_content)
                    })
                    break;
                // 转接客服接收转接成功后的消息
                case 604:
                    // console.log('message =====>' , message);
                    // console.log('chatLogContent =====>' , chatLogContent);
                    this.getLogs(chatLogContent)
                    break;
                // 通知弹窗
                case 605:
                    this.$nextTick(() => {
                        // this.$refs.transferModal.openModal()
                    })
                    break;
                // 通知转接发起人转接超时
                case 606:
                    this.receiveMessage(message, 'new')
                    break
                case 700:
                    this.msgNotice(message)
                    break;
                case 425:
                    this.myChatUserInfo();
                    break;
                case 607:
                    if (!this.isMinimize) this.isMinimize = true;
                default:
                    break;
            }
        },
        async getNowCustomerInfo() {
            this.isLoadingCustomerInfo = true
            const [ err, res ] = await getNowCustomerInfo()
            this.isLoadingCustomerInfo = false
            if (err) return
            this.customerInfo = res.data
        },

        // 获取当前登陆的客服信息
        getMyInfo(chatLogContent) {
            this.myInfo = chatLogContent;
            this.$store.commit('chat/setCustomerInfo', this.myInfo);
        },
        // 接收消息
        receiveMessage(message, type) {
            let sourceItem = this.chatlogs.find(v => v.source == message.Source);
            if (!sourceItem) {
                this.chatlogs.push({
                    ...message,
                    source: message.Source,
                    unReadCount: 0,
                    SenderHeadImg: message.SenderHeadImg,
                    children: [
                        {
                            senderId:  this.myInfo.SenderId == message.SenderId ? message.ReceiverId : message.SenderId,
                            senderName: this.myInfo.SenderId == message.SenderId ? message.Receiver : message.Sender,
                            sessionId: message.SessionId,
                            user_id: message.user_id,
                            isEnd: false,
                            hasCountDown: false,
                            countdown: this.config[message.Source].timeout_response_time,
                            unReadCount: 0,
                            children: [message]
                        }
                    ]
                })
            }
            else {
                let userItem = sourceItem.children.find(v => v.senderId == message.SenderId || v.senderId == message.ReceiverId);
                if (!userItem) {
                    sourceItem.children.push({
                        ...message,
                        senderId:  this.myInfo.SenderId == message.SenderId ? message.ReceiverId : message.SenderId,
                        senderName: this.myInfo.SenderId == message.SenderId ? message.Receiver : message.Sender,
                        sessionId: message.SessionId,
                        user_id: message.user_id,
                        isEnd: false,
                        hasCountDown: false,
                        countdown: this.config[message.Source].timeout_response_time,
                        unReadCount: 0,
                        children: [message]
                    });
                }
                else {
                    userItem.isEnd = false;
                    console.log(userItem.children.map(v => v.msg_id_string), message.msg_id_string)
                    if (!userItem.children.find(v => v.msg_id_string === message.msg_id_string) || message.msg_id_string === null) {
                        userItem.children.push(message);
                    }
                }
            };
            {
                let item = this.chatlogs.find(v => v.source == message.Source);
                // 客服发的消息重新计时
                if (this.myInfo.SenderId == message.SenderId) {
                    if (item) {
                        let userItem = item.children.find(v => v.senderId == message.ReceiverId);
                        if (userItem) {
                            userItem.hasCountDown = false;
                            userItem.countdown = this.config[message.Source].timeout_response_time;
                            userItem.unReadCount = 0;
                            this.$refs.chatGroup.calcUnReadCount();
                            clearTimeout(userItem.timer);
                        }
                    }
                } else {
                    if (item) {
                        let userItem = item.children.find(v => v.senderId == message.SenderId);
                        if (userItem && this.currentUser.senderId != message.SenderId) {
                            userItem.isEnd = false;
                            if (message.IfRead === true || message.IfRead === false) {
                                if (message.IfRead === false) userItem.unReadCount++;
                            } else {
                                userItem.unReadCount++;
                            }
                            this.$refs.chatGroup.calcUnReadCount();
                        }
                        if (type == 'new') {
                            if (userItem && !userItem.hasCountDown) {
                                this.countdown(userItem);
                            }
                        } else if (type == 'getLog') {
                            const lastMessage = userItem.children[userItem.children.length - 1];
                            if (lastMessage.SenderId == this.myInfo.SenderId) {
                                userItem.hasCountDown = false;
                                userItem.countdown = this.config[lastMessage.Source].timeout_response_time;
                                clearTimeout(userItem.timer);
                            } else {
                                userItem.countdown = lastMessage.countdown;
                                if (!userItem.hasCountDown) {
                                    this.countdown(userItem);
                                }
                            }
                        }
                    }
                }
            }

            if (this.currentGroup.source) {
                this.currentGroup = this.chatlogs.find(v => v.source == this.currentGroup.source);
                if (this.currentUser.senderId) {
                    this.currentUser = this.currentGroup.children.find(v => v.senderId == this.currentUser.senderId);
                }
            }
            if (this.myInfo.SenderId == message.SenderId) {
                if (this.$refs.chatMessage) {
                    this.$refs.chatMessage.text = '';
                    this.$refs.chatMessage.isOpen = false;
                    this.$nextTick(() => {
                        this.$refs.chatMessage.isOpen = true;
                    });
                }
            } else {
                if (type == 'new') {
                    this.newMessage = true;
                    this.audioPlay();
                }
            }

            // 转接成功后，需要更新当前用户的sessionId
            if (message.SessionId) {
                this.currentUser.sessionId = message.SessionId
            }
            // console.log(this.chatlogs)
        },
        getLogs(chatLogContent) {
            const msgLogs = chatLogContent.text_content;
            if (msgLogs.length) {
                msgLogs.forEach(v => {
                    if (v.ReceiverId == this.myInfo.SenderId) v.position = 'left';
                    else v.position = 'right';
                    this.receiveMessage(v, 'getLog')
                })
            }
        },
        // 结束会话
        endSession(message) {
            // if (this.currentUser.senderId) {
            //     this.currentUser.isEnd = true;
            //     this.currentUser.hasCountDown = false;
            //     this.currentUser.countdown = this.config[message.Source].timeout_response_time;
            //     this.currentUser.unReadCount = 0;
            //     this.$refs.chatGroup.calcUnReadCount();
            //     clearTimeout(this.currentUser.timer);
            // };
            this.chatlogs.forEach(v => {
                if (v.source == message.Source) {
                    if (v.children && v.children.length) {
                        v.children.forEach(_v => {
                            if (_v.senderId == message.SenderId || _v.senderId == message.ReceiverId) {
                                _v.isEnd = true;
                                _v.hasCountDown = false;
                                _v.countdown = this.config[message.Source].timeout_response_time;;
                                _v.unReadCount = 0;
                                this.$refs.chatGroup.calcUnReadCount();
                                clearTimeout(_v.timer);
                            }
                        })
                    }
                }
            });
        },
        // 清除所有未读消息
        clearUnRead() {
            this.chatlogs.forEach(v => {
                if (v.source === this.currentGroup.source) {
                    if (v.children && v.children.length) {
                        v.children.forEach(_v => {
                            _v.unReadCount = 0;
                            sendMessage({
                                ChatMessageType: 423,
                                ReceiverId: v.senderId
                            })
                        })
                    }
                }
            });
            this.$refs.chatGroup.calcUnReadCount();
        },
        getCurrentUser(e) {
            this.currentUser = e;
            this.$nextTick(() => {
                this.$refs.chatGroup.calcUnReadCount();
                if (this.$refs.opera) {
                    this.$refs.opera.orderData = [];
                    this.$refs.opera.showUserOrder = false;
                }
            })
        },
        groupChange(e) {
            this.currentGroup = e;
            this.$refs.chatUser.currentTab = null;
            this.$refs.chatUser.currentWindow = 'current'
            this.currentUser = {};
            this.$store.commit('chat/setUserSource', this.currentGroup.source)
        },
        getChatTime(chatList) {
            let array = chatList.reverse();
            let item = array.find(v => { return v.SenderId != this.myInfo.SenderId });
            if (item) return item.ChatLogSendTime
        },
        getChatLogContent(chatList) {
            let array = chatList.reverse();
            let item = array.find(v => { return v.SenderId != this.myInfo.SenderId });
            if (item) return {
                text_content: item.send_content,
            }
        },
        // 发送智能回复
        sendReply(e) {
            this.$refs.chatMessage.sendReply(e);
        },
        // 获取当前所有状态
        async getServiceStatus() {
            const [err, res] = await getChatCustomerUserServiceList({
                page: 1,
                limit: 9999
            });
            if (err) return;
            this.statusOptions = res.data.list;
        },
        // 获取当前客服状态
        async myChatUserInfo() {
            const [err, res] = await getMySelfChatCustomerUserInfo();
            if (err) return;
            this.statusId = res.data.chat_customer_user_service_id
        },
        // 客服切换状态
        async changeMyServiceStatus(e) {
            const [err, res] = await changeMySelfChatCustomerUserService({
                chat_customer_user_service_id: e
            });
            if (err) return;
            this.$message.success('切换状态成功');
            this.myChatUserInfo();

            const findRes = this.statusOptions.find(v => v.id === e)
            // 需要重连
            if (findRes.is_reconnect === 1) {
                closeWs()
                this.currentUser = {}
                this.currentGroup.children = []
                this.isReconnecting = true
                this.chatlogs.forEach(v => {
                    v.unReadCount = 0
                })
                this.initWebsocket(true)
                this.getWaitCustomerDetail()
            }
        },

        // 获取分组
        async getSourcelist() {
            const [err, res] = await getPrintSourceList({
                page: 1,
                limit: 999
            });
            if (err) return;
            this.sourceList = res.data.list;
        },

        buildGroup(sourceList, chatlogs) {
            let groupList = [];
            sourceList.forEach(v => {
                chatlogs.forEach(_v => {
                    if (v.id == _v.Source) {
                        if (!groupList.some(v => v.id == _v.Source)) {
                            groupList.push({ ...v, })
                        }
                    }
                })
            });
            return groupList;
        },

        // 消息通知
        msgNotice(message) {
            const h = this.$createElement;
            let count = this.messageNoticeQueue.length

            if (this.messageNoticeQueue.length >= 1) {
                this.messageNoticeQueue.forEach(v => {
                    v.notice.close()
                })
            }

            count += 1
            message.notice = Notification({
                message: h('div', { style: 'text-align: left;position: relative;', }, [
                    h('div', {
                        style: `position: absolute; right: -9px; top: -10px; border-radius: 50%; background-color: #f56c6c; height: 18px; padding: 0 6px; color: #fff; font-size: 12px; display: ${count > 1 ? 'block' : 'none'}`
                    }, count),
                    h('div', {}, [
                        h('span', { style: 'color: #409eff;margin-right:12px;font-weight:bold;' }, 'DING'),
                        h('span', { style: 'font-weight:bold' }, message.ChatLogSendTime)
                    ]),
                    h('div', { style: 'margin-top:6px' }, message.ChatLogContent.text_content.content),
                    h('div', { style: 'text-align: right;margin-top:8px' }, [
                        h('el-button', {
                            class: 'el-button--default el-button--mini', on: {
                                'click': () => {
                                    this.messageNoticeQueue = []
                                    console.log(this.messageNoticeQueue)
                                    message.notice.close();
                                }
                            }
                        }, '稍后处理'),
                        h('el-button', {
                            class: 'el-button--primary el-button--mini', on: {
                                'click': () => {
                                    this.messageNoticeQueue = []
                                    router.push({
                                        name: 'admin.customer_v2.notify.news'
                                    })
                                    message.notice.close();
                                }
                            }
                        }, '立即处理')
                    ])
                ]),
                position: 'bottom-right',
                duration: 0,
                showClose: false,
                customClass: 'chat-notice'
            });
            this.messageNoticeQueue.push(message)
        },

        audioPlay() {
            if (this.audio != null) {
                this.audio.pause();
                this.audio = null;
            };
            this.audio = document.getElementById('imNewAudio');

            const promise = this.audio.play();
            if (this.playNum >= 1) return;
            this.playNum++;
            if (promise !== undefined) {
                promise.then(res => {

                }).catch(error => {
                    this.$confirm('是否授权本次访问自动播放音频?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {

                        if (this.audio) this.audio.play();
                    }).catch(() => {

                    });
                })
            }
        },
        overAudio() {
            this.stopAudio();
        },
        stopAudio() {
            if (this.audio) {
                this.audio.pause();
                this.audio = null;
            }
        },
        mousedown(e) {
            setTimeout(() => {
                this.canClick = true;
                //去除默认样式 - 避免拖动元素出现禁止图标
                e.preventDefault && e.preventDefault();
                //获取目标元素
                let odiv = e.target;
                odiv.style.zIndex = 999999;

                //算出鼠标相对元素的位置
                let disY = e.clientY - odiv.offsetTop;
                let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
                //监听鼠标移动事件
                document.onmousemove = (e) => {
                    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    let top = e.clientY - disY;
                    //重新赋值

                    if (top >= clientHeight - 30) {
                        top = clientHeight - 30;
                    } else if (top <= 110) {
                        top = 110
                    }
                    this.messageTop = top + 'px';
                    this.canClick = false;
                };
                //监听鼠标松开
                document.onmouseup = (e) => {
                    document.onmousemove = null;
                    document.onmouseup = null;
                    odiv.style.zIndex = 9999;
                    setTimeout(() => {
                        this.canClick = true;
                    })
                };
            }, 20)
        },
        messageClick() {
            if (!this.canClick) return;
            this.isMinimize = !this.isMinimize;
            this.newMessage = false;
            this.$emit('minimize', 'chat_v2');
        },
        getOrderList() {
            this.$refs.opera.getOrderList()
        },

        onSelectUserBefore() {
            if (this.$refs.chatMessage) {
                this.$refs.chatMessage.isLoadingList = true
            }
        },

        onSelectUserAfter() {
            if (this.$refs.chatMessage) {
                this.$refs.chatMessage.isLoadingList = false
            }
        }
    },
    watch: {
        currentGroup: {
            handler(val) {
                // console.log(val);
            },
            deep: true,
            immediate: true
        }
    },
    async created() {
        // await this.getStatuslabel();
        // await this.getPrintsourcelist();
        // await this.getChatlog();
        // await this.geSourcelist();
        // await this.initWebsocket();
    },
};
</script>
<style lang="scss" scoped>
.minimize {
    position: fixed;
    right: 0px;
    top: calc(80vh - 40px);
    border: 1px solid transparent;
    padding: 6px 12px;
    border-radius: 16px 0px 0px 16px;
    background: rgb(25, 26, 35);
    color: #fff;
    cursor: pointer;
    z-index: 99999;
    user-select: none;
}
.cart-controller {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 50px);
    background: #eee;
    position: fixed;
    width: 100%;
    top: 15px;
    z-index: 3000;
    width: 95%;
    left: 2.5%;
    border-radius: 8px;
    padding-top: 24px;

    .window-top_left {
        position: absolute;
        top: 3px;
        left: 42px;
        display: flex;
        align-items: center;

        div {
            font-size: 18px;
            font-weight: 500;
        }

        span {
            color: #409EFF;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.2s;
    }
    .fade-enter {
        opacity: 0;
        transform: translateX(50px);
    }
    .fade-leave-to {
        opacity: 0;
        transform: translateX(-50px);
    }
    .fade-list-enter-active,
    .fade-list-enter-to {
        transition: all 100.2s;
    }
    .fade-list-enter-active {
        opacity: 0;
        transform: translateY(100%);
    }
    .fade-list-enter-to {
        opacity: 1;
        transform: translateY(0);
    }
    // .fade-enter-active,
    // .fade-leave-active {
    //     transition: opacity 0.3s;
    // }
    // .fade-enter,
    // .fade-leave-to {
    //     opacity: 0;
    // }
    .box {
        width: 100%;
        height: calc(100% - 32px);
        margin: 40px;
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #ddd;
        box-shadow: 0px 0px 7px 3px rgba(62, 62, 62, 0.05);
        display: flex;
        position: relative;
        overflow: hidden;

        .reconnect-wrap {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }

        .chat {
            width: 69%;
            display: inline-block;
            .top {
                height: 50px;
                border-bottom: 1px solid #e8e8e8;
                padding: 0 16px;
            }
            .bottom {
                height: calc(100% - 51px);
                display: flex;
                > .left {
                    width: 72%;
                    height: 100%;
                    border: 1px solid #e8e8e8;
                    flex-shrink: 0;
                }
                > .right {
                    width: 28%;
                    height: 100%;
                    flex-shrink: 0;
                }
            }
        }
    }
    :deep(.status-modal) {
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            padding: 24px;
        }
    }
}

.flashing {
    /*动画元素名-动画整个过程的时间*/
    animation: hzfirst 1s;
    /*动画次数*/
    animation-iteration-count: infinite;
}

@keyframes hzfirst {
    from {
        background-color: #ff7300;
    }
    to {
        background-color: #000;
    }
}
</style>

<style lang="scss">
.chat-notice {
    padding: 6px 12px 12px 12px !important;
    max-height: 400px;
    width: 320px;
    .el-notification__group {
        margin: 0;
    }
}
</style>
