/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-09-02 10:09:57
 * @LastEditors: llg
 * @LastEditTime: 2022-09-02 11:26:42
 */
// 导出全局组件

import AppDialog from '@/components/Common/AppDialog.vue'
import MenuLeft from '@/components/Layout/MenuLeft'
import TopBar from '@/components/Layout/TopBar'
import WorkTab from '@/components/Layout/WorkTab'
import Personality from '@/components/Layout/Personality'
import AppLayout from '@/components/Common/AppLayout'

export default (Vue)=>{
  Vue.component("app-dialog", AppDialog)
  Vue.component("menu-left", MenuLeft)
  Vue.component("top-bar", TopBar)
  Vue.component("work-tab", WorkTab)
  Vue.component("personality", Personality)
  Vue.component("app-layout", AppLayout)
}
