/*
 * @Version: 1.0
 * @Autor: llg
 * @Date: 2022-08-04 11:17:40
 * @LastEditors: llg
 * @LastEditTime: 2022-08-04 11:18:34
 */
import { doGet, doPost, post } from '../index'
// 字典
export const getScreeningList = data => doGet('/api/v1/common/getScreeningList')(data)

export const getDictOption = data => doPost('/openapi/v1/backstageScreening/getBackstageScreeningList')(data)


export const getBackstageScreeningTypeServiceList = data => doPost('/api/v2/backstageScreeningType/getBackstageScreeningTypeServiceList')(data)
export const addBackstageScreeningTypeService = data => doPost('/api/v2/backstageScreeningType/addBackstageScreeningTypeService')(data)
export const updateBackstageScreeningTypeService = data => doPost('/api/v2/backstageScreeningType/updateBackstageScreeningTypeService')(data)
export const deleteBackstageScreeningTypeService = data => doPost('/api/v2/backstageScreeningType/deleteBackstageScreeningTypeService')(data)
export const clearBackstageScreeningTypeCache = data => post('/api/v2/backstageScreeningType/clearBackstageScreeningTypeCache')(data)


export const getBackstageScreeningServiceList = data => doPost('/api/v2/backstageScreening/getBackstageScreeningServiceList')(data)
export const addBackstageScreeningService = data => doPost('/api/v2/backstageScreening/addBackstageScreeningService')(data)
export const updateBackstageScreeningService = data => doPost('/api/v2/backstageScreening/updateBackstageScreeningService')(data)
export const deleteBackstageScreeningService = data => doPost('/api/v2/backstageScreening/deleteBackstageScreeningService')(data)
