<template>
    <div class="chat-message" v-loading="isLoadingList">
        <div style="display: flex; flex-direction: column; height: 100%;">
            <div class="chat-content" ref="scrollDiv" @click="showEmoji = false" :style="{ height: currentWindow === 'history' ? '100%' : '70%' }">
                <div class="message" :class="[item.position == 'left' ? 'left' : item.position == 'right' ? 'right': 'center']" v-for="(item, index) in currentUser.children" :key="index">
                    <template v-if="item.position == 'left'">
                        <!--{{ item }}-->
                        <div>
                            <span class="name">{{item.Sender || item.senderName}}</span>
                            <span>{{ item.ChatLogSendTime || item.send_time }}</span>
                        </div>
                        <div>
                            <!--{{ currentUser }}-->
                            <el-avatar icon="el-icon-user-solid" :src="item.SenderHeadImg || item.sender_img"></el-avatar>
                            <div v-if="item.ChatLogContent.screen_type == 2" class="image">
                                <el-image style="width: 100px; height: 100px" :src="item.ChatLogContent.text_content" :preview-src-list="[item.ChatLogContent.text_content]">
                                </el-image>
                            </div>
                            <div v-else>{{item.ChatLogContent.text_content}}</div>
                        </div>
                    </template>
                    <template v-else-if="item.position == 'right'">
                        <!--{{ item }}-->
                        <div>
                            <span class="name">{{item.Sender || item.senderName}} </span>
                            <span>{{ item.ChatLogSendTime || item.send_time }}</span>
                        </div>
                        <div>
                            <div v-if="item.ChatLogContent.screen_type == 2" class="image">
                                <el-image style="max-width: 100px; height: 100px; object-fit: cover;" :src="item.ChatLogContent.text_content" :preview-src-list="[item.ChatLogContent.text_content]">
                                </el-image>
                            </div>
                            <!-- 订单卡片 -->
                            <div v-else-if="item.ChatLogContent.screen_type === 5" class="order-card" style="flex: 1;">
                                <el-card shadow="never">
                                    <el-form label-width="90px" label-suffix="：">
                                        <el-row>
                                            <el-col :span="12">
                                                <el-form-item label="订单编号">
                                                    <div style="text-align: left;"><span style="word-break: break-all">{{ item.ChatLogContent.text_content.cust_order_id }}</span></div>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item label="订单状态">
                                                    <div style="text-align: left;"><span style="word-break: break-all">{{ item.ChatLogContent.text_content.order_status }}</span></div>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item label="下单时间">
                                                    <div style="text-align: left;"><span style="word-break: break-all">{{ item.ChatLogContent.text_content.order_time }}</span></div>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item label="订单金额">
                                                    <div style="text-align: left;"><span style="word-break: break-all">{{ item.ChatLogContent.text_content.total_money }}</span></div>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item label="打印文件">
                                                    <div style="text-align: left;"><span style="word-break: break-all">{{ item.ChatLogContent.text_content.print_document_txt }}</span></div>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item label="打印项目">
                                                    <div style="text-align: left;"><span style="word-break: break-all">{{ item.ChatLogContent.text_content.public_model_name }}</span></div>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-form>
                                </el-card>

                            </div>
                            <div v-else>{{item.ChatLogContent.text_content}}</div>
                            <el-avatar icon="el-icon-user-solid" :src="item.SenderHeadImg || item.sender_img"></el-avatar>
                        </div>
                    </template>
                    <template v-else-if="item.position == 'center'">
                        <div>
                            {{item.ChatLogContent.text_content}}
                        </div>
                    </template>
                </div>
            </div>

            <template v-if="currentWindow === 'current'">
                <div class="opera-content">
                    <div style="position: relative; display: flex; align-items: center;width: 22px;height: 22px;">
                        <picker
                            v-if="showEmoji"
                            :showSearch="false"
                            :showPreview="false"
                            :showCategories="false"
                            :include="['recent', 'people']"
                            :recent="recent"
                            @select="onEmojiSelect"
                            :i18n="{
                            search: '搜索',
                            categories: {
                                search: '搜索结果',
                                recent: '最近使用',
                                people: '所有表情',
                                nature: '动物',
                                foods: '事物',
                                activity: '活动',
                                places: 'Travel & Places',
                                objects: 'Objects',
                                symbols: '符号',
                                flags: 'Flags',
                                custom: 'Custom',
                            }
                        }"
                        >
                        </picker>

                        <i class="el-icon-picture-outline" style="position: absolute;cursor: not-allowed;color:#ddd;" v-if="currentUser.isEnd"></i>
                        <img :src="smile" alt="" style="width: 24px; height:24px; margin: 0 10px 0 0" @click.stop="openEmoji()">
                        <el-upload class="upload-demo" action="/api/v1/uploadFile/uploadImg" :accept="'.png,.jpg'" :show-file-list="false" :data="{ upload_type: 1 }" :on-success="uploadSuccess" :on-error="uploadError">
                            <i class="el-icon-picture-outline"></i>
                        </el-upload>
                    </div>
                    <div>
                        <div @click="getOrderList">
                            获取单号
                        </div>
                        <!-- <div @click="getUserRobotChatlog">
                            获取记录
                        </div> -->
                    </div>
                </div>
                <div class="typing-area">
                    <!-- <el-input type="textarea" show-word-limit :maxlength="200" placeholder="请输入内容" v-model="text" resize="none" @keydown.enter.native="sendMessage()">
                    </el-input> -->
                    <Editor v-if="isOpen" v-model="text" :defaultConfig="editorConfig" :mode="'simple'" @onCreated="onCreated" @keydown.enter.native="send()" @onFocus="showEmoji = false"></Editor>
                    <div>
                        <i class="el-icon-loading image-loading" v-if="loading"></i>
                        <el-button type="primary" size="mini" @click="send()" :disabled="!text.trim() || currentUser.isEnd">发送</el-button>
                    </div>
                </div>
            </template>
            <template v-else-if="currentWindow === 'wait'">
                <div class="waiting-text">
                    已排队等待时长：{{ currentUser.waitTime }}分钟
                </div>
            </template>
        </div>
        <chat-order ref="chatOrder" :currentUser="currentUser" :currentGroup="currentGroup" />
        <chat-log-robot ref="chatLogRobot" />
    </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css';
import ChatOrder from './ChatOrder.vue';
import ChatLogRobot from './ChatLogRobot.vue';
import { Editor } from '@wangeditor/editor-for-vue';
import { errHandler } from '@/utils/utils'
import { uploadImg } from '@/api/phpApi/commonApi'
import { sendMessage } from '@/utils/websocket';
import smile from '@/assets/img/smile.png'
import { Picker } from 'emoji-mart-vue'
export default {
    props: {
        currentUser: {
            type: Object,
            default: () => {
                return {}
            }
        },
        currentGroup: {
            type: Object,
            default: () => {
                return {}
            }
        },
        websocket: {},
        myInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },

        currentWindow: {
            type: String,
            default: ''
        },
    },
    components: {
        ChatOrder,
        ChatLogRobot,
        Editor,
        Picker
    },

    data() {
        return {
            text: '',
            dataList: [],
            userInfo: {},
            isOpen: true,
            editor: '',
            isLoadingList: false,
            loading: false,
            smile: smile,
            showEmoji: false,

            baseRecent: [],
            recent: [],

            // 编辑器配置
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    uploadImage: {
                        // 接口地址
                        server: '/api/v1/uploadFile/uploadImg',
                        // 最大文件个数
                        maxNumberOfFiles: 2000,
                        // 文件格式
                        allowedFileTypes: ['image/*'],
                        // 自定义上传
                        customUpload: async (file, insertFn) => {
                            this.loading = true;
                            const name = file.name
                            const [err, res] = await errHandler(uploadImg, {
                                file: file,
                                upload_type: 1
                            })
                            if (err) {
                                console.log(err)
                                this.loading = false;
                                return
                            }
                            if (res.code === 0) {
                                insertFn(res.data.url, name);
                                setTimeout(() => {
                                    this.loading = false;
                                    this.editor.focus(true);
                                }, 300)
                            }
                        }
                    },
                    editImage: null
                }
            },

            // 当前已等待时间
            waitingTime: 0
        };
    },

    methods: {
        closeChat() {
            this.$emit('closeChat')
        },

        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        onEmojiSelect(e) {
            if (this.baseRecent.length === 8) {
                this.baseRecent.pop()
            }
            this.baseRecent = this.baseRecent.filter(v => v !== e.id)
            this.baseRecent.unshift(e.id)

            const editor = this.editor
            editor.focus()
            editor.insertText(e.native)
            this.showEmoji = false
            window.localStorage.setItem(this.$store.state.user.user.info.userId, this.baseRecent)
        },
        openEmoji() {
            this.showEmoji = !this.showEmoji
        },
        parseHtml(html) {
            const result = [];
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');

            for (let node of doc.body.childNodes) {
                if (node.nodeType === Node.TEXT_NODE) {
                    const text = node.textContent.trim();
                    if (text !== '') {
                        result.push({ text, type: 1 });
                    }
                } else if (node.nodeType === Node.ELEMENT_NODE) {
                    if (node.tagName === 'IMG') {
                        const url = node.getAttribute('src');
                        if (url) {
                            result.push({ text: url, type: 2 });
                        }
                    } else {
                        const childrenHtml = node.innerHTML;
                        const childrenParsed = this.parseHtml(childrenHtml);
                        result.push(...childrenParsed);
                    }
                }
            }
            return result
        },
        mergeHtmlArray(arr) {
            let result = [];
            let textResult = [];
            let text = '';
            for (let i = 0; i < arr.length; i++) {
                const item = arr[i];
                if (item.type == 2) {
                    textResult.forEach(v => text += v.text);
                    if (textResult.length) {
                        result.push({
                            type: 1,
                            text: text
                        });
                        textResult = [];
                        text = '';
                    }
                    result.push(item);
                } else {
                    textResult.push(item);
                }
            };
            if (textResult.length) {
                textResult.forEach(v => text += v.text);
                result.push({
                    type: 1,
                    text: text
                });
            }
            return result;
        },
        send() {
            let e = window.event || arguments[0];
            let messageList = this.mergeHtmlArray(this.parseHtml(this.editor.getHtml()));
            if ((e.key == 'Enter' || e.code == 'Enter' || e.keyCode == 13) && !e.shiftKey || e.type == 'click') {
                e.returnValue = false;
                if (!messageList.length) {
                    this.$message.error('不能发送空消息');
                    return;
                };
                if (this.currentUser.isEnd) {
                    this.$message.error('会话已断开，不能发送消息');
                    return;
                }
                for (let i = 0; i < messageList.length; i++) {
                    let message = {
                        ChatMessageType: 402,
                        ChatLogContent: {
                            text_content: messageList[i].text,
                            screen_type: messageList[i].type
                        }
                    }
                    sendMessage(message)
                }

                return false
            }
        },
        sendReply(opt) {
            if (opt.type == 'edit') {
                this.text = opt.item.answer;
            } else if (opt.type == 'send') {
                if (this.currentUser.isEnd) {
                    this.$message.error('会话已断开，不能发送消息');
                    return;
                };
                let message = {
                    ChatMessageType: 402,
                    ChatLogContent: {
                        text_content: opt.item.answer,
                        screen_type: 1
                    }
                };
                sendMessage(message)
            }
        },
        async getOrderList() {
            // this.$emit('getOrderList')
            this.$refs.chatOrder.openModal({
                Source: this.currentUser.source,
                SysUserId: this.currentUser.senderId
            });
        },
        async getUserRobotChatlog() {
            this.$refs.chatLogRobot.openModal({
                source: this.currentUser.source,
                chatUserId: this.currentUser.senderId
            });
        },
        uploadSuccess(response) {
            if (response.code == 0) {
                let message = {
                    ChatMessageType: 402,
                    ChatLogContent: {
                        screen_type: 2,
                        text_content: response.data.url,
                    }
                }
                sendMessage(message)
            } else {
                this.uploadError();
            }
        },
        uploadError() {
            this.$message.error('发送图片失败');
        },
    },
    created() {
        const recent = (window.localStorage.getItem(this.$store.state.user.user.info.userId))?.split(',')

        this.baseRecent = recent || []
        if (recent) {
            for (const item of recent) {
                this.recent.push(item)
            }
        }
        this.userInfo = this.$store.getters['chat/onlineUser'];
    },
    watch: {
        'currentUser.children': {
            handler(v) {
                this.$nextTick(() => {
                    console.log(this.currentUser)
                    let scrollElem = this.$refs.scrollDiv;
                    if (scrollElem) scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
                });
            },
            deep: true,
            immediate: true
        },

        showEmoji(val) {
            while(this.recent.length) {
                this.recent.pop()
            }
            for (const item of this.baseRecent) {
                this.recent.push(item)
            }

            window.localStorage.setItem(this.$store.state.user.user.info.userId, this.recent)
        }
    },
};
</script>
<style lang="scss">
.chat-message {
    position: relative;

    .w-e-image-container {
        overflow: hidden;
        position: relative;
        vertical-align: bottom;
    }
    .w-e-text-container {
        p {
            margin: 0 !important;
        }
        .w-e-text-placeholder {
            top: 0;
            left: 10px !important;
            font-style: normal;
        }
    }
    .w-e-image-container {
        img {
            width: 160px;
        }
        .w-e-image-dragger {
            display: none;
        }
    }
    .w-e-image-container:hover {
        box-shadow: none;
    }
    .w-e-hover-bar {
        display: none;
    }
}
</style>
<style lang="scss" scoped>
.chat-message {
    height: 100%;

    .chat-content {
        height: 70%;
        background: #ededed;
        overflow: auto;
        > .message {
            padding: 12px 18px;
        }
        > .left {
            text-align: left;
            .name {
                margin-right: 6px;
            }
            > div:nth-child(1) {
                margin-left: 40px;
                color: #c8c8c8;
                margin-bottom: 4px;
            }
            > div:nth-child(2) {
                display: flex;
                //align-items: center;
                > span:nth-child(1) {
                    width: 32px;
                    height: 32px;
                    margin-right: 6px;
                    line-height: 32px;
                    vertical-align: middle;
                    flex-shrink: 0;
                }
                > div:nth-child(2):not(.image):not(.order-card) {
                    background: #ffffff;
                    padding: 8px 14px;
                    border-radius: 30px;
                    max-width: 45%;
                    line-height: 1.5;
                    word-break: break-all;
                }
            }
        }
        > .right {
            text-align: right;
            .name {
                margin-left: 6px;
            }
            > div:nth-child(1) {
                margin-right: 40px;
                color: #c8c8c8;
                margin-bottom: 2px;
            }
            > div:nth-child(2) {
                display: flex;
                //align-items: center;
                justify-content: flex-end;

                > span:nth-child(2) {
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                    margin-left: 6px;
                    vertical-align: middle;
                }
                > div:nth-child(1):not(.image):not(.order-card) {
                    background: #0096ff;
                    padding: 8px 14px;
                    border-radius: 30px;
                    max-width: 50%;
                    line-height: 1.5;
                    color: #fff;
                    text-align: left;
                    word-break: break-all;
                }
            }
        }
        > .center {
            display: flex;
            > div {
                background: #aaa;
                border-radius: 4px;
                display: inline-block;
                padding: 6px 12px;
                color: #fff;
            }
        }
    }
    .opera-content {
        height: 42px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        i {
            font-size: 22px;
            margin-right: 20px;
            cursor: pointer;
        }
        > div:nth-of-type(2) {
            display: flex;
            align-items: center;
            > div:nth-child(2),
            > div:nth-child(1) {
                background: #ff9541;
                border-radius: 16px;
                padding: 4px 18px;
                color: #fff;
                cursor: pointer;
            }
            > div:nth-child(1) {
                margin-right: 12px;
            }
        }
    }
    .typing-area {
        position: relative;
        height: calc(30% - 44px);
        .image-loading {
            position: absolute;
            top: 6px;
            right: 6px;
            font-size: 20px;
        }
        > div:nth-child(1) {
            height: calc(100% - 64px);
            outline: none;
            padding: 12px 0 0 0;
            overflow: auto;
            margin-bottom: 12px;
            font-size: 15px;
            width: 100%;
            :deep(.el-textarea__inner) {
                height: 100%;
                border: 0;
                padding: 0;
            }
        }
        > div:nth-child(2) {
            text-align: right;
            margin-right: 12px;
            :deep(.el-button) {
                width: 80px;
            }
        }
    }
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

:deep(.emoji-mart) {
    position: absolute;
    left: -12px;
    top: -212px;
    padding: 6px 6px;
    width: 400px !important;
    height: 200px !important;

    .emoji-mart-category-label {
        font-size: 15px;
        //display: none;
    }
}

.waiting-text {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}

::v-deep .el-avatar img {
    width: 100%;
}
</style>
