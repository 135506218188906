<template>
    <el-drawer size="265px" :visible.sync="show" :with-header="false" :before-close="close">
        <vue-scroll :ops="ops" ref="scroll" class="scroll">
            <div class="drawer-con">
                <p class="title" style="margin-top: 35px">菜单风格</p>
                <div class="menu-theme-wrap">
                    <div class="item" v-for="item in themeList" :key="item.theme" @click="setUpTheme(item.theme)">
                        <div class="box">
                            <div class="top" :style="{ background: item.tabbarBackground + '!important' }"></div>
                            <div class="left" :style="{ background: item.menuLeftBc + '!important' }"></div>
                        </div>
                        <div class="active" v-if="item.theme === currentTheme"></div>
                    </div>
                </div>

                <p class="title" style="margin-top: 40px">基础配置</p>
                <div class="basic-box">
                    <div class="item" style="display: flex">
                        <span>侧边栏开启手风琴模式</span>
                        <el-switch v-model="uniqueOpened" active-color="#1F8CEB" inactive-color="#cccccc" @change="setLeftMenuUniqueOpened">
                        </el-switch>
                    </div>
                    <div class="item">
                        <span>显示折叠侧边栏按钮</span>
                        <el-switch v-model="showMenuButton" active-color="#1F8CEB" inactive-color="#cccccc" @change="setLeftMenuButton">
                        </el-switch>
                    </div>
                    <div class="item">
                        <span>显示刷新按钮</span>
                        <el-switch v-model="showRefreshButton" active-color="#1F8CEB" inactive-color="#cccccc" @change="setPageRefreshButton">
                        </el-switch>
                    </div>
                    <div class="item mobile-hide">
                        <span>显示全局面包屑导航</span>
                        <el-switch v-model="showCrumbs" active-color="#1F8CEB" inactive-color="#cccccc" @change="setLeftMenuCrumbs">
                        </el-switch>
                    </div>
                    <div class="item">
                        <span>开启多标签页</span>
                        <el-switch v-model="showWorkTab" active-color="#1F8CEB" inactive-color="#cccccc" @change="showWorkTabFunc">
                        </el-switch>
                    </div>
                    <div class="item">
                        <span>显示顶部进度条</span>
                        <el-switch v-model="showNprogress" active-color="#1F8CEB" inactive-color="#cccccc" @change="showNprogressFunc">
                        </el-switch>
                    </div>
                    <div class="item">
                        <span>开启搜索缓存</span>
                        <el-switch v-model="searchCache" active-color="#1F8CEB" inactive-color="#cccccc" @change="searchCacheFunc">
                        </el-switch>
                    </div>
                    <div class="item">
                        <span>开启水印</span>
                        <el-switch v-model="isWartermark" active-color="#1F8CEB" inactive-color="#cccccc" @change="onWartermarkChange">
                        </el-switch>
                    </div>
                    <div class="item">
                        <span>水印文案</span>
                        <el-input v-model="wartermarkText" size="mini" style="width: 120px" @input="onWartermarkTextInput($event)" placeholder="水印文案"></el-input>
                    </div>
                    <!-- <div class="item">
                        <span>自动关闭设置中心</span>
                        <el-switch v-model="autoClose" active-color="#1F8CEB" inactive-color="#cccccc" @change="setPersonalityAutoClose">
                        </el-switch>
                    </div> -->
                    <!-- <div class="item">
                        <span>列表滚动条记忆</span>
                        <el-switch v-model="showTableScroll" active-color="#1F8CEB" inactive-color="#cccccc" @change="setTableScrollAutoClose">
                        </el-switch>
                    </div> -->
                </div>
            </div>
        </vue-scroll>
    </el-drawer>
</template>

<script>
import setting from "@/config/setting";
import { mapState } from "vuex";
import { scrollBarOpstion } from "@/config/scrollbar.js";
import Watermark from '@/utils/wartermark';

export default {
    props: {
        show: Boolean,
    },
    computed: {
        ...mapState({
            setting: (state) => state.setting.setting,
        }),
    },
    watch: {
        "setting.theme"(theme) {
            this.currentTheme = theme;
        },
        "setting.autoClose"(close) {
            this.autoClose = close;
        },
    },
    data() {
        return {
            themeList: setting.themeList,
            dayThemeList: [
                {
                    theme: "white",
                    color: ["#fff", "#fff"],
                },
                {
                    theme: "dark",
                    color: ["#22252A"],
                },
                {
                    theme: "auto",
                    color: ["#fff", "#22252A"],
                },
            ],
            currentTheme: "",
            uniqueOpened: true,
            showMenuButton: true,
            autoClose: false,
            showRefreshButton: true,
            showCrumbs: true,
            showWorkTab: true,
            showLanguage: true,
            showNprogress: true,
            showTableScroll: true,
            isWartermark: false,
            wartermarkText: null,
            searchCache: false,
            currentView: setting.themeModel,
            dayList: [6, 18],
            userName: this.$store.state.user.user.info.user_name,
            ops: scrollBarOpstion,
        };
    },
    mounted() {
        this.initUserSetting();
        this.initThemeModel();
        this.initThemeSetting();
    },
    methods: {
        // 初始化用户主题设置
        initThemeSetting() {
            let { defaultTheme } = setting;
            let t = defaultTheme;
            let sys = JSON.parse(localStorage.getItem("sys"));

            if (sys) {
                let { theme } = sys.user.setting;
                if (theme) {
                    t = theme;
                } else {
                    t = defaultTheme;
                }
            }

            this.setUpTheme(t);
        },
        // 设置主题
        setUpTheme(theme) {
            this.currentTheme = theme;
            this.$store.dispatch("setting/setUpTheme", theme);
            this.isAutoClose();
        },
        initThemeModel() {
            let { currentView, dayList } = this;

            if (currentView === "auto") {
                this.setDarkTheme("auto");
            } else {
                this.setDarkTheme(currentView);
            }
        },
        setDarkTheme(theme) {
            let el = document.getElementsByTagName("html")[0];

            if (theme === "dark") {
                el.setAttribute("class", "dark-body");
            } else if (theme === "white") {
                el.removeAttribute("class");
            } else if (theme === "auto") {
                this.setAutoThemeModel(el);
            }
            this.currentView = theme;
            this.setThemeModel();
        },
        setAutoThemeModel(el) {
            let d = new Date();
            let h = d.getHours();
            let { dayList } = this;
            this.currentView = "auto";
            this.setThemeModel();

            if (h >= dayList[0] && h < dayList[1]) {
                el.removeAttribute("class");
            } else {
                el.setAttribute("class", "dark-body");
            }
        },
        // 初始化用户设置
        initUserSetting() {
            let {
                uniqueOpened,
                menuButton,
                autoClose,
                showRefreshButton,
                showCrumbs,
                themeModel,
                showWorkTab,
                showLanguage,
                showNprogress,
                showTableScroll,
                isWartermark,
                wartermarkText,
                searchCache,
            } = this.setting;

            this.uniqueOpened = uniqueOpened;
            this.showMenuButton = menuButton;
            this.autoClose = autoClose;
            this.showRefreshButton = showRefreshButton;
            this.showCrumbs = showCrumbs;
            this.currentView = themeModel;
            this.showWorkTab = showWorkTab;
            this.showLanguage = showLanguage;
            this.showNprogress = showNprogress;
            this.showTableScroll = showTableScroll;
            this.isWartermark = isWartermark;
            this.wartermarkText = wartermarkText;
            this.searchCache = searchCache;
            if (!themeModel) {
                this.currentView = setting.themeModel;
            };
            this.isWartermark ? Watermark.set(`${this.wartermarkText} - ${this.userName}`) : Watermark.del();
        },
        // 是否开启手风琴模式
        setLeftMenuUniqueOpened() {
            this.$store.dispatch("setting/setLeftMenuUniqueOpened", {
                show: this.uniqueOpened,
            });
            this.isAutoClose();
        },
        // 是否显示菜单展开按钮
        setLeftMenuButton() {
            this.$store.dispatch("setting/setLeftMenuButton", {
                show: this.showMenuButton,
            });
            this.isAutoClose();
        },
        // 是否显示局部刷新按钮
        setPageRefreshButton() {
            this.$store.dispatch("setting/setPageRefreshButton", {
                show: this.showRefreshButton,
            });
            this.isAutoClose();
        },
        // 是否显示全局面包屑
        setLeftMenuCrumbs() {
            this.$store.dispatch("setting/setLeftMenuCrumbs", {
                show: this.showCrumbs,
            });
            this.isAutoClose();
        },
        // 自动关闭
        setPersonalityAutoClose() {
            this.$store.dispatch("setting/setPersonalityAutoClose", {
                show: this.autoClose,
            });
            this.isAutoClose();
        },
        setTableScrollAutoClose() {
            this.$store.dispatch("setting/setTableScrollAutoClose", {
                show: this.showTableScroll,
            });
            this.isAutoClose();
        },
        showWorkTabFunc() {
            this.$store.dispatch("setting/setWorkTab", {
                show: this.showWorkTab,
            });
            this.isAutoClose();
        },
        showLanguageFunc() {
            this.$store.dispatch("setting/setLanguage", {
                show: this.showLanguage,
            });
            this.isAutoClose();
        },
        showNprogressFunc() {
            this.$store.dispatch("setting/setNprogress", {
                show: this.showNprogress,
            });
            this.isAutoClose();
        },
        searchCacheFunc() {
            this.$store.dispatch("setting/setSearchCache", {
                show: this.searchCache,
            });
            if (!this.searchCache) {
                this.$store.dispatch("search/clearSearchParamsCache");
            }
            this.isAutoClose();
        },
        onWartermarkChange() {
            this.$store.dispatch("setting/setWartermark", {
                show: this.isWartermark,
            });
            this.isWartermark && this.wartermarkText ? Watermark.set(`${this.wartermarkText} - ${this.userName}`) : Watermark.del();
        },
        onWartermarkTextInput() {
            this.$store.dispatch("setting/setWartermarkText", {
                text: this.wartermarkText,
            });
            if (this.wartermarkText === '') return false;
            if (this.wartermarkText && this.isWartermark) Watermark.set(`${this.wartermarkText} - ${this.userName}`);
        },
        // 主题模式
        setThemeModel() {
            this.$store.dispatch("setting/setThemeModel", this.currentView);
            this.isAutoClose();
        },
        // 自动关闭
        isAutoClose() {
            let { autoClose } = this;
            if (autoClose) {
                this.show = false;
                this.$emit("closePersonality");
            }
        },
        close() {
            this.$emit("click");
        },
    },
};
</script>

<style>
/* 去除drawer点击时候出现的边框 */
.el-drawer:focus {
    outline: none !important;
}

.el-switch .el-switch__core {
    width: 35px;
}
</style>

<style lang="scss" scoped>
.scroll {
    .drawer-con {
        height: 100vh;
        padding: 0 20px;

        .title {
            text-align: center;
            color: #000;
            font-size: 13px;
            position: relative;

            &:first-of-type {
                margin-top: 20px;
            }

            &::before {
                content: "";
                width: 50px;
                border-bottom: 2px solid #f3f4f5;
                position: absolute;
                left: 0;
                top: 10px;
                margin: auto;
            }

            &::after {
                content: "";
                width: 50px;
                border-bottom: 2px solid #f3f4f5;
                position: absolute;
                right: 0;
                top: 10px;
                margin: auto;
            }
        }

        .theme-wrap {
            display: flex;
            flex-wrap: wrap;
            margin-top: 25px;

            .item {
                width: 65px;
                box-sizing: border-box;
                margin-right: 15px;

                &:last-of-type {
                    margin-right: 0;
                }

                .box {
                    width: 100%;
                    height: 45px;
                    box-shadow: 0 0 5px #ccc;
                    position: relative;
                    background: #f5f7f9;
                    box-sizing: border-box;
                    border-radius: 6px;
                    transition: box-shadow 0.1s;
                    cursor: pointer;
                    display: flex;
                    overflow: hidden;

                    > div {
                        width: 50%;
                        height: 100%;
                    }
                }

                .name {
                    text-align: center;
                    margin-top: 6px;
                    color: #515a6e;
                    font-size: 12px;
                }

                .active {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    position: relative;
                    left: 0;
                    right: 0;
                    bottom: -5px;
                    margin: auto;
                    background: #19be6b !important;
                }
            }
        }

        .menu-theme-wrap {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;

            .item {
                width: 65px;
                margin-right: 15px;

                .box {
                    width: 65px;
                    height: 45px;
                    box-shadow: 0 0 5px #ccc;
                    position: relative;
                    background: #f5f7f9 !important;
                    box-sizing: border-box;
                    border-radius: 6px;
                    overflow: hidden;
                    transition: box-shadow 0.1s;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 0 0 10px #ccc;
                    }

                    &:nth-child(even) {
                        margin-right: 0;
                    }

                    .top {
                        width: 100%;
                        height: 8px;
                    }

                    .left {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 16px;
                        height: 100%;
                    }
                }

                .active {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    margin: auto;
                    margin-top: 8px;
                    background: #19be6b !important;
                }
            }
        }

        .basic-box {
            .item {
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
                align-items: center;

                span {
                    color: #515a6e;
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (max-width: $device-ipad) {
    .mobile-hide {
        display: none !important;
    }
}
</style>