import axios from "axios";
import store from "../store";
import router from "../router";
import { baseUrl } from "./config.js";
import { Message } from "element-ui";
import refreshToken from './refreshToken';
import loading from '@/api/menuApi';
import md5 from 'js-md5';
let cancel, promiseArr = {};
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const specialApi = ['getBackstageScreeningList']
let isRefreshing = false;
let requests = [];

function getApiVersion(config) {
    const versionPattern = /\/v(\d+)\//;
    const match = config.url.match(versionPattern);
    if (match && match[1]) {
        return match[1];
    }
    return;
}

//请求拦截器
axios.interceptors.request.use(
    config => {
        config.cancelToken = source.token // 全局添加cancelToken

        // 发起请求时，取消掉当前正在进行的相同请求
        // if (promiseArr[config.url]) {
        //     promiseArr[config.url]("操作取消")
        //     promiseArr[config.url] = cancel
        // } else {
        //     promiseArr[config.url] = cancel
        // }

        // 拼接域名
        // config.url = baseUrl + config.url;
        let { token } = store.state.user.user.info;
        const timestamp = new Date().getTime();
        config.headers = {
            "Content-Type": config.headers['Content-Type'] ? config.headers['Content-Type'] : "application/json",
            "Authorization": "Bearer " + token,
            "timestamp": timestamp,
            "sign": md5(JSON.stringify({
                ...config.data,
                timestamp,
            }))
        };
        const version = getApiVersion(config);
        if (version) config.headers['version'] = `v${version}`;

        if (router.app._route && router.app._route.meta && router.app._route.meta.id) {
            const menuId = router.app._route.meta.id;
            config.headers['menuId'] = menuId;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//响应拦截器即异常处理
axios.interceptors.response.use(
    response => {
        let { code, message, msg } = response.data;
        let { url } = response.config;
        if (code == 403) router.push({ name: 'notPermission' })

        if (code == 401) router.push({ name: 'login' })

        if (url.includes(specialApi)) {
            if (code && code != 200 && code != 401) {
                Message.error(message || msg);
                `throw new Error()`
            }

            if (response) {
                return response;
            }
        } else {
            if (code && code !== 200 && code != 0 && code != 401) {
                Message.error(message || msg);
                throw new Error()
            }

            if (response) {
                return response;
            }
        }
    },
    err => {
        if (err && err.response) {
            const response = err.response;
            switch (err.response.status) {
                case 400:
                    err.message = "错误请求";
                    break;
                case 401:
                    // err.message = "未授权，请重新登录！";
                    // router.push({ path: '/login' });
                    if (!isRefreshing) {
                        isRefreshing = true
                        //调用刷新token的接口
                        refreshToken().then(res => {
                            if (res.data.code == 500) {
                                router.push({ path: '/login' });
                            };
                            const { token } = res.data
                            // 替换token
                            store.dispatch("user/setToken", token);
                            response.headers.Authorization = `Bearer ${token}`
                            // // token 刷新后将数组的方法重新执行
                            requests.forEach((cb) => cb(token))
                            requests = [] // 重新请求完清空
                            return axios(response.config)
                        }).catch(err => {
                            //跳到登录页
                            router.push({ path: '/login' });
                            return Promise.reject(err)
                        }).finally(() => {
                            isRefreshing = false
                        })
                    } else {
                        // 返回未执行 resolve 的 Promise
                        return new Promise(resolve => {
                            // 用函数形式将 resolve 存入，等待刷新后再执行
                            requests.push(token => {
                                response.headers.Authorization = `Bearer ${token}`
                                resolve(axios(response.config))
                            })

                        })
                    }
                    break;
                case 403:
                    err.message = "拒绝访问";
                    break;
                case 404:
                    err.message = "请求错误,未找到该资源！";
                    break;
                case 405:
                    err.message = "请求方法未允许";
                    break;
                case 408:
                    err.message = "请求超时";
                    break;
                case 500:
                    err.message = "服务器端出错";
                    break;
                case 501:
                    err.message = "网络未实现";
                    break;
                case 502:
                    err.message = "网络错误";
                    break;
                case 503:
                    err.message = "服务不可用";
                    break;
                case 504:
                    err.message = "网络超时";
                    break;
                case 505:
                    err.message = "http版本不支持该请求";
                    break;
                default:
                    err.message = `连接错误${err.response.status}`;
            }
        } else {
            err.message = "连接到服务器失败";
        }

        if (err && err.response) {
            if (err.response.status != 401) Message.error(err.message || err.msg);
        }

        return Promise.reject(err);
    }
);

axios.defaults.timeout = 1000000000000;

//自定义请求方式
function request(config) {
    return new Promise((resolve, reject) => {
        axios(config).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
}

//get请求方式
function get(config) {
    return new Promise((resolve, reject) => {
        axios({
            url: config.url,
            method: "get",
            params: config.data
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
}

//post请求方式
function post(config) {
    return new Promise((resolve, reject) => {
        axios({
            url: config.url,
            method: "post",
            data: config.data
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err);
        });
    });
}

function downloadPost(config) {
    return new Promise((resolve, reject) => {
        axios({
            url: config.url,
            method: "post",
            data: config.data,
            responseType: "blob"
        })
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        });
    });
}

//put请求方式
function put(config) {
    return new Promise((resolve, reject) => {
        axios({
            url: config.url,
            method: "put",
            data: config.data
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err);
        });
    });
}

//deleteh请求方式
function deleted(config) {
    return new Promise((resolve, reject) => {
        axios({
            url: config.url,
            method: "delete",
            params: config.data
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err);
        });
    });
}

// formData请求方式
function postFormData(config) {
    return new Promise((resolve, reject) => {
        axios({
            url: config.url || '/api/v1/uploadFile/uploadImg',
            method: "post",
            data: config.formData,
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'
            },
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
}

export default {
    request,
    get,
    post,
    downloadPost,
    put,
    deleted,
    postFormData
};
