<template>
    <app-dialog :visible.sync="visible" title="添加常用语" width="400px" @confirm="addChatCustomerCommonReplies" @closed="$refs.form.resetFields()">
        <el-form ref="form" :model="params" :rules="rules" label-width="120px">
            <el-form-item prop="type_id" label="选择分类：">
                <el-select v-model="params.type_id" style="width: 100%;">
                    <el-option v-for="item in typeOptions" :key="item.id" :label="item.type_name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="content" label="常用语内容：">
                <el-input v-model="params.content" type="textarea" :maxlength="30" :rows="4"></el-input>
            </el-form-item>
        </el-form>
    </app-dialog>
</template>

<script>
    import AppDialog from '@/components/Common/AppDialog.vue'

    import { getChatCustomerCommonRepliesTypeData, addChatCustomerCommonReplies } from '@/api/phpApi/chatApiV2'

    export default {
        name: 'AddShortcutMessage',
        components: {
            AppDialog
        },
        data() {
            return {
                visible: false,
                params: {
                    content: null,
                    type_id: null
                },
                rules: {
                    type_id: [
                        { required: true, message: '请选择分类', trigger: ['change', 'blur'] }
                    ],
                    content: [
                        { required: true, message: '请输入常用语内容', trigger: ['change', 'blur'] }
                    ]
                },

                typeOptions: []
            }
        },

        methods: {
            async openModal() {
                await this.getChatCustomerCommonRepliesTypeData()
                this.visible = true
            },

            addChatCustomerCommonReplies() {
                this.$refs.form.validate(async valid => {
                    if (!valid) return
                    const [ err, res ] = await addChatCustomerCommonReplies(this.params)
                    if (err) return
                    this.$message.success(res.msg)
                    this.visible = false
                    this.$emit('refreshData')
                })
            },

            async getChatCustomerCommonRepliesTypeData() {
                const [ err, res ] = await getChatCustomerCommonRepliesTypeData()
                if (err) return
                this.typeOptions = [
                    {
                        id: 0,
                        type_name: '默认库'
                    },
                    ...res.data
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
