<template>
    <app-dialog :visible.sync="visible" title="添加分类" width="400px" @confirm="addChatCustomerCommonRepliesType" @closed="$refs.form.resetFields()">
        <el-form ref="form" :model="params" :rules="rules">
            <el-form-item prop="type_name" label="分类名称：" label-width="100px" required>
                <el-input v-model="params.type_name" :maxlength="20" placeholder="请输入分类名称，最多20个字符" cla></el-input>
            </el-form-item>
        </el-form>
    </app-dialog>
</template>

<script>
    import AppDialog from '@/components/Common/AppDialog.vue'

    import { addChatCustomerCommonRepliesType } from '@/api/phpApi/chatApiV2'

    export default {
        name: 'AddUsefulExpressionsType',
        components: {
            AppDialog
        },
        data() {
            return {
                visible: false,
                params: {
                    type_name: null
                },
                rules: {
                    type_name: [
                        { required: true, message: '请输入分类名称', trigger: ['change', 'blur'] }
                    ]
                }
            }
        },

        methods: {
            openModal() {
                this.visible = true
            },

            addChatCustomerCommonRepliesType() {
                this.$refs.form.validate(async valid => {
                    if (!valid) return
                    const [ err, res ] = await addChatCustomerCommonRepliesType(this.params)
                    if (err) return
                    this.$message.success(res.msg)
                    this.visible = false
                    this.$emit('refreshData')
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
