<template>
    <div class="chat-transfer">
        <el-dialog title="转接" :visible.sync="visible" width="850px" :modal-append-to-body='false' append-to-body :close-on-click-modal="false">
            <el-form :model="form" :rules="rules" ref="form" label-width="auto">
                <el-form-item label="受理产品：">
                    {{formatValue(currentGroup.source)}}
                </el-form-item>
                <el-form-item label=" " prop="to_id" label-width="38px" style="padding:0">
                    <div>
                        <div>请选择移交客服：</div>
                        <div>
                            <el-table :data="customerList" border style="width: 100%" @row-click="singleElection" highlight-current-row v-loading="loading">
                                <el-table-column align="center" width="55" label="选择">
                                    <template slot-scope="scope">
                                        <el-radio class="radio" v-model="form.to_id" :label="scope.row.id">&nbsp;</el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="username" label="账号名称" width="120"> </el-table-column>
                                <el-table-column align="center" prop="nickname" label="客服名称" width="120"> </el-table-column>
                                <el-table-column align="center" prop="group_list" label="服务组">
                                    <template slot-scope="scope">
                                        <span v-for="(item, index) in scope.row.group_list" :key="item.id">
                                            <span v-if="index != 0">、</span>
                                            <span>{{formatGroup(item.id)}}</span>
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="service_type" label="服务类型">
                                    <template slot-scope="scope">
                                        <div v-dict-convert="{dictData: serviceTypeList}">{{scope.row.service_type}}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="is_online" label="客服状态" width="80">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.is_online == 1">在线</span>
                                        <span v-else>离线</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>

                </el-form-item>
                <el-form-item label="用户归属服务：" prop="service_type">
                    <el-select v-model="form.service_type" placeholder="请选择用户归属服务" style="width:50%" clearable>
                        <el-option :label="item.label" :value="item.value" v-for="item in serviceTypeList" :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="转接备注：">
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入转接备注" v-model="form.remark" style="width:50%">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visible = false">取 消</el-button>
                <el-button v-debounce type="primary" @click="transfer">转 接</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
// import { getCustomer, transfer } from '@/api/chatApi';
import { getChatCustomerUser, getChatCustomerSessionInfo, customerTransfer } from '@/api/phpApi/chatApiV2'
import dictService from '@/utils/dictService';
import { sendMessage } from '@/utils/websocket';
export default {
    props: {
        currentUser: {
            type: Object,
            default: () => { return {} }
        },
        sourceList: {
            type: Array,
            default: () => { return [] }
        },
        currentGroup: {
            type: Object,
            default: () => { return {} }
        },
        serviceTypeList: {
            type: Array,
            default: () => { return [] }
        }
    },

    data() {
        return {
            visible: false,
            tipVisible: false,
            isTransfer: true,
            form: {
                to_id: '',
                service_type: '',
                switch_type: 1,
                session_id: '',
                remark: ''
            },
            rules: {
                to_id: [
                    { required: true, message: '请选择受理客服', trigger: 'change' },
                ],
                service_type: [
                    { required: true, message: '请选择用户归属服务', trigger: 'change' },
                ]
            },
            customerList: [],
            checkList: [],
            groupList: [],
            loading: false
        };
    },

    inject: ['onTransferChange'],

    methods: {
        singleElection(row) {
            this.form.to_id = row.id
        },
        async transfer() {
            this.form.session_id = this.currentUser.sessionId;
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    const [err, res] = await customerTransfer(this.form);
                    if (err) return;
                    this.visible = false;
                    sendMessage({
                        ChatMessageType: 605,
                        ChatLogContent: {}
                    })

                    this.onTransferChange({
                        type: 'open',
                        data: {
                            allocation_rules_wait_time: res.data.allocation_rules_wait_time
                        }
                    })
                    return false;
                }
            });
        },
        formatValue(sourceId) {
            if (!sourceId || !this.sourceList.length) {
                return;
            };
            let item = this.sourceList.find(v => { return v.id == sourceId });
            if (item) return item.name;
            return '未知';
        },
        formatGroup(groupId) {
            if (!groupId || !this.groupList.length) {
                return;
            };
            let item = this.groupList.find(v => { return v.id == groupId });
            if (item) return item.name;
            return '未知';
        },
        async getCurrentServiceType(sessionId) {
            const [err, res] = await getChatCustomerSessionInfo({
                sessionId: sessionId
            })
            if (err) return;
            this.form.service_type = res.data.service_type;
        },
        async getCustomer(sourceId) {
            this.loading = true;
            const [err, res] = await getChatCustomerUser({
                page: 1,
                limit: 1000,
                source: sourceId
            });
            if (err) return;
            this.customerList = res.data.list;
            this.loading = false;
        },
    },
    async mounted() {
        const res = await dictService.getDictByUrl('/api/v2/chatUserGroup/getChatUserGroupList');
        this.groupList = res;
        // this.serviceTypeList = res['service_type'];
    }
};
</script>
<style lang="scss" scoped>
.chat-transfer {
    ::deep(.el-dialog__body) {
        max-height: 160px;
        overflow: auto;
    }
}
</style>

