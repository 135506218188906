<template>
    <div class="frame" :style="{ paddingLeft, paddingTop }">
        <!-- 左侧菜单 -->
        <menu-left></menu-left>

        <!-- 顶栏、选项卡 -->
        <top-bar @openSetting="openSetting">
            <work-tab v-if="showWorkTab" />
        </top-bar>

        <!-- 内容区 -->
        <div class="container">
            <transition name="fade" mode="out-in">
                <router-view :style="{ minHeight }" ref="child" :key="$route.fullPath"></router-view>
            </transition>
        </div>

        <!-- 个性化 -->
        <personality :show="personalityOpen" @click="openSetting" @closePersonality="closePersonality" />

        <div class="notice-wrap">
            <!--<MyNotification ref="myNotification" :data="data"></MyNotification>-->

            <message-notice ref="messageNotice" :data="data"></message-notice>
            <!--<MyNotification ref="myNotification" :data="data"></MyNotification>-->
        </div>

        <!-- <MessageNotification ref="messageNotification" :data="data"></MessageNotification> -->
        <div id="chat_v1"></div>
        <div id="chat_v2"></div>
    </div>
</template>

<script>
import { menuLeftOpenWidth, menuLeftShrinkWidth } from "@/config/menu";
import componentFactory from "@/utils/componentFactory";
import componentAuth from '@/utils/componentAuth'
import { mapState } from "vuex";
import MyNotification from "@/components/Notification/MyNotification.vue";
import MessageNotification from "@/components/Notification/MessageNotification.vue";
import MessageNotice from '@/components/Notification/MessageNotice.vue'

import * as signalR from "@aspnet/signalr";
import Chat_V1 from '@/pages/admin/customer/chat/Index.vue'
import Chat_V2 from '@/pages/admin/customer_v2/chat/Index.vue'

import { noticeBusinessApi } from '@/api/noticeApi'
import { orderNoticeBusinessApi } from '@/api/orderNoticeApi'
import dictService from '@/utils/dictService'

export default {
    components: {
        MessageNotification,
        MyNotification,
        MessageNotice,
        Chat_V1,
        Chat_V2
    },
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            isRouterAlive: true, // KeepAlive
            personalityOpen: false, // 个性化可见性
            showWorkTab: true, // 显示多标签
            data: {
                orderNoticeData: [],
                messageNoticeData: [],
                taskNoticeData: [],
                shipmentsNoticeData: [],
            },
            conn: null,
            hasChat: false,
            hasV2Chat: false,

            noticeConnect: null,

            export_type_list: []
        };
    },
    computed: {
        ...mapState({
            setting: (state) => state.setting.setting,
            user: (state) => state.user.user,
            menu: (state) => state.menu.menuList,
        }),
        menuOpen() {
            return this.$store.state.menu.menuOpen;
        },
        paddingLeft() {
            let width = this.menuOpen ? menuLeftOpenWidth : menuLeftShrinkWidth;
            this.$store.dispatch("menu/setMenuWidth", width);
            return width;
        },
        paddingTop() {
            return this.showWorkTab ? "105px" : "75px";
        },
        minHeight() {
            return `calc(100vh - ${this.showWorkTab ? "120px" : "90px"})`;
        },
    },
    watch: {
        "setting.showWorkTab": {
            handler(show) {
                this.showWorkTab = show;
            },
            immediate: true,
        },
        "menu": {
            handler(menuList) {
                if (menuList.id) {
                    this.$nextTick(() => {
                        this.initChat();
                    })
                }
            },
            immediate: true,
        },
    },
    async mounted() {
        this.saveUserData();
        this.getDict()
        let { token, sys_type } = this.$store.state.user.user.info;
        // this.$refs.messageNotification.show();
        // if (sys_type == 'BusinessAdmin') {
        //     // this.getOrderMessage(token)
        //     this.getNotReadCount()
        //     this.getNoticeMessage(token)
        // };
        this.getNotReadCount()
        this.getNoticeMessage(token,sys_type)
    },
    methods: {
        initChat() {
            let chat_v1, chat_v2;
            // if (componentAuth('admin.customer.chat')) {
            //     chat_v1 = componentFactory(Chat_V1, '#chat_v1');
            //     chat_v1.$on('minimize', () => {
            //         if (!chat_v2.isMinimize) chat_v2.isMinimize = true;
            //         this.addModal(chat_v1, chat_v2)
            //     })
            // }
            if (componentAuth('admin.customer_v2.chat')) {
                chat_v2 = componentFactory(Chat_V2, '#chat_v2');
                chat_v2.$on('minimize', () => {
                    this.addModal(chat_v2)
                })
                chat_v2.init();
            };
        },
        addModal(chat_v2) {
            if (chat_v2.isMinimize) {
                document.body.style.overflow = 'auto';
                let modal = document.getElementById('chat_modal');
                if (modal) document.body.removeChild(modal);
            } else {
                if (document.getElementById('chat_modal')) return;
                let modal = document.createElement('div');
                modal.style.cssText = 'position:fixed;top:0;left:0;right:0;bottom:0;z-index:1001;background:rgba(0,0,0,.5);';
                modal.id = 'chat_modal';
                document.body.appendChild(modal);
                document.body.style.overflow = 'hidden';
            }
        },
        // 讲 vuex 中的数据保存到 localStorage 中（在即将离开页面(刷新或关闭)时执行）
        saveUserData() {
            let _self = this;
            window.addEventListener("beforeunload", () => {
                _self.$store.dispatch("user/storeStorage");
            });
        },
        // 刷新页面
        reload() {
            this.$refs.child.reload()
        },
        // 打开 ｜ 关闭 设置
        openSetting() {
            this.personalityOpen = !this.personalityOpen;
        },
        closePersonality() {
            this.personalityOpen = false;
        },
        getOrderMessage(token) {
            this.conn = new signalR.HubConnectionBuilder().withUrl('/api/SignalHub', {
                accessTokenFactory: () => token
            }).configureLogging(signalR.LogLevel.Error).build();
            // 保证这个接收是唯一的
            this.conn.off("SetBusinessSettlerEvent");
            this.conn.on("SetBusinessSettlerEvent", message => {
                this.$nextTick(() => {
                    this.data = message;
                    if (this.data && this.data.length) {
                        this.$refs.myNotification.show();
                    } else {
                        this.$refs.myNotification.closeNotification();
                        this.$refs.myNotification.stopAudio();
                    }
                })
            })
            this.conn.start();
        },

        async getNotReadCount() {
            noticeBusinessApi.getNotificationNotReadCount().then(([err, res]) => {
                if (res) {
                    this.$store.commit('menu/setNotReadNoticeCount', res.data.count)
                }
            })

            orderNoticeBusinessApi.getOrderNotificationNotReadCount().then(([err, res]) => {
                if (res) {
                    this.$store.commit('menu/setNotReadOrderCount', res.data.count)
                }
            })
        },

        async getNoticeMessage(token, sys_type) {
            const sourceList = await dictService.getDictByUrl('/api/v1/printSource/getPrintSourceList')

            this.noticeConnect = new signalR.HubConnectionBuilder()
                .withUrl(`/backstageNotice/noticeHub`, {
                    accessTokenFactory: () => token
                })
                .configureLogging(signalR.LogLevel.Error)
                .build()

            // 重新连接
            this.noticeConnect.onclose(async () => {
                console.log('SignalR connection closed due to an error. Attempting to reconnect...')
                await new Promise(resolve => setTimeout(resolve, 500)) // wait 5 seconds before attempting to reconnect

                try {
                    await this.noticeConnect.start()
                    console.log('SignalR connection re-established.')
                } catch (err) {
                    console.log(`Error while attempting to reconnect: ${err}`)
                }
            })

            this.noticeConnect.on('AcceptMessage', async data => {
                data = JSON.parse(data)
                if (data.type === 'announcement' && sys_type === 'BusinessAdmin') {
                    this.data.messageNoticeData.push({
                        ...data,
                        group: `message-group_${data.notification_id}`,
                        showBage: false
                    })
                } else if (data.type === 'order' && sys_type === 'BusinessAdmin') {
                    this.data.orderNoticeData.push({
                        ...data,
                        source: sourceList.find(v => v.id === Number(data.source)).name,
                        group: `order-group_${data.notification_id}`,
                        showBage: false
                    })
                } else if (data.type === 'task') {
                    const export_type = this.export_type_list.find(v => v.value === data.export_export).label
                    this.data.taskNoticeData.push({
                        ...data,
                        group: `task-group_${data.task_id}`,
                        export_type_label: export_type,
                        showBage: false
                    })
                } else if (data.type === 'shipments') {
                    this.data.shipmentsNoticeData.push({
                        ...data,
                        source: sourceList.find(v => v.id === Number(data.source)).name,
                        group: `order-group_${data.order_id}`,
                        showBage: false
                    })
                }
                // this.$refs.messageNotice.show()
                // console.log(data)
            })
            this.noticeConnect.start()
        },
        async getDict() {
            this.export_type_list = (await dictService.getDictByType({ type: 'task' })).export_type
        }
    },
    destroyed() {
        if (this.conn) this.conn.stop();
    }
};
</script>

<style lang="scss" scoped>
.frame {
    width: 100%;
    min-height: 100vh;
    padding: 0 0 15px 0;
    box-sizing: border-box;
    transition: padding 0.3s ease-in-out;
    background: $default-background;
    overflow: hidden;

    .container {
        width: calc(100% - 30px);
        min-height: 100%;
        margin: auto;
        box-sizing: border-box;

        // 子页面默认style
        .page-content {
            background: #fff;
            padding: 16px;
            position: relative;
            box-sizing: border-box;
            border-radius: 3px;

            .open-head-btn {
                width: 100%;
                height: 15px;
                cursor: pointer;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 999;
                text-align: center;
                font-size: 12px;
                color: #eee;
            }
        }
    }
}

.notice-wrap {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

@media only screen and (max-width: $device-ipad) {
    .frame {
        width: 100%;
        padding-left: 0 !important;
        min-height: 100vh;
        overflow-y: scroll;

        .container {
            width: calc(100% - 20px);
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
