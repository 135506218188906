<template>
    <div>
        <div class="chat-header">
            <div class="left">
                <span>{{currentUser.senderName}}</span>
                <template v-if="currentWindow === 'current'">
                    <span @click="closeMessage" v-show="!currentUser.isEnd">结束对话</span>
                    <span style="margin-left:36px">
                    <span>倒计时：</span>
                    <span :style="{color: currentUser.countdown > 0 ? '' : 'red'}">{{currentUser.countdown > 0 ? currentUser.countdown : '已超时'}}</span>
                </span>
                </template>
            </div>
            <div class="right">
                <template v-if="currentWindow === 'current'">
                    <span @click="transfer" v-if="!currentUser.isEnd">
                    <i class="el-icon-refresh-left"></i>
                    <span>转接</span>
                </span>
                </template>
            </div>
        </div>
        <chat-transfer ref="chatTransfer" :currentUser="currentUser" :currentGroup="currentGroup" :sourceList="sourceList" :serviceTypeList="serviceTypeList" />
        <chat-transfer-list ref="chatTransferList" />
    </div>
</template>

<script>
import { sendMessage } from '@/utils/websocket';
import ChatTransfer from './ChatTransfer.vue'
import ChatTransferList from './ChatTransferList.vue';
import dictService from '@/utils/dictService';
export default {
    props: {
        currentUser: {
            type: Object,
            default: () => { return {} }
        },
        sourceList: {
            type: Array,
            default: () => { return [] }
        },
        currentGroup: {
            type: Object,
            default: () => { return {} }
        },
        websocket: {},
        myInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },

        currentWindow: {
            type: String,
            default: ''
        },
    },
    components: {
        ChatTransfer,
        ChatTransferList
    },

    inject: ['onTransferChange'],

    data() {
        return {
            options: [],
            statusId: '',
            serviceTypeList: []
        };
    },

    methods: {
        // 转接
        async transfer() {
            this.$refs.chatTransfer.visible = true;
            this.$refs.chatTransfer.isTransfer = false;
            this.$nextTick(async () => {
                await this.$refs.chatTransfer.getCurrentServiceType(this.currentUser.sessionId);
                await this.$refs.chatTransfer.getCustomer(this.currentGroup.source);
            })
        },
        getChatTransferList() {
            this.$refs.chatTransferList.openModal()
        },
        closeMessage() {
            this.$confirm('确定结束对话吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let message = {
                    ChatMessageType: 404,
                    ChatLogContent: {}
                }
                sendMessage(message)
            }).catch(() => {
            });
        }
    },
    async mounted() {
        const res = await dictService.getDictByType({ type: 'customer' });
        this.serviceTypeList = res['service_type'];
    }
};
</script>
<style lang="scss" scoped>
.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    user-select: none;
    .left {
        > span:nth-child(1) {
            margin-right: 12px;
            font-weight: bold;
            font-size: 14px;
        }
        > span:nth-child(2) {
            border: 1px solid #00d2ff;
            border-radius: 15px;
            padding: 4px 16px;
            cursor: pointer;
            color: #00d2ff;
        }
    }
    .right {
        i {
            font-size: 18px;
            cursor: pointer;
            vertical-align: middle;
        }
        > span:nth-child(1) {
            cursor: pointer;
            span {
                vertical-align: middle;
                color: #00d2ff;
            }
            i {
                color: #00d2ff;
                margin-right: 4px;
            }
        }
        > span:nth-child(2) {
            margin-left: 30px;
        }
        // > span:nth-child(2) {
        //     border: 1px solid #00d2ff;
        //     border-radius: 15px;
        //     padding: 4px 16px;
        //     cursor: pointer;
        // }
    }
}
</style>
