import store from "@/store";
import { Message } from "element-ui";

const msgType = {
    70: '获取FAQ等数据',
    71: '点击FAQ找答案',
    72: '换一组FAQ',
    73: '点击FAQ分类',
    200: '登陆用户信息',
    300: '未接入人工时用户发消息',
    301: '选择服务类型',
    302: '接入客服排队中',
    303: '结束排队',
    400: '中间消息提示',
    401: '人工聊天用户端消息',
    402: '人工聊天客服端消息',
    403: '用户手动结束会话',
    404: '客服手动结束会话',
    405: '结束服务推送用户评价结构',
    406: '用户提交评价',
    407: '用户提交评价结果',
    408: '是否解决问题',
    410: '推送接入客服信息',
    1000: '获取基础配置',
    999: '心跳'
}


let ws = null;
// 是否允许重连
let allowReconnect = true;
const heartCheck = {
    timeout: 30000,
    timeoutObj: null,
    serverTimeoutObj: null,
    reset: () => {
        clearTimeout(heartCheck.timeoutObj);
        clearTimeout(heartCheck.serverTimeoutObj);
        return heartCheck;
    },
    start: () => {
        heartCheck.timeoutObj = setTimeout(() => {
            sendMessage(heartMsgTpl)
            heartCheck.serverTimeoutObj = setTimeout(() => {
                ws.close();
            }, heartCheck.timeout)
        }, heartCheck.timeout)
    }
};
let lockReconnect = false;
let reconnectNum = 0;
let reconnectTimeout = null;
let onMessageCb = null;
let heartMsgTpl = {
    ChatMessageType: 999,
    ...store.state.chat.customerInfo,
}


export function webSocketInit(cb, options = {}) {
    const failCb = options.fail ?? function() {}

    let { token } = store.state.user.user.info;
    let protocol;
    if (location.protocol == 'http:') {
        protocol = 'ws:'
    } else if (location.protocol == 'https:') {
        protocol = 'wss:'
    };
    // ws = new WebSocket(`ws://192.168.1.8:11000/customer?authorization=Bearer ${token}&source=0`);
    ws = new WebSocket(`${protocol}//${process.env/**/.VUE_APP_CUSTOMER_API_URL}?authorization=Bearer ${token}&source=${0}`);
    // ws = new WebSocket(`ws://192.168.66.188:9930/customer?authorization=Bearer ${token}&source=${0}`);
    // ws = new WebSocket(`${protocol}//${location.host}/customer?authorization=Bearer ${token}&source=${source}`);
    // this.websocket = new WebSocket(`ws://imapitest.66in.net/customer?authorization=Bearer ${this.urlQuery.token}&source=${this.urlQuery.source}`);
    ws.onopen = function () {
        reconnectNum = 0;
        heartCheck.reset().start();
        cb && cb({
            message: '连接成功',
            code: 200
        })
    };
    ws.onmessage = function (e) {
        let message = JSON.parse(e.data);
        if (message.ChatMessageType == 500) {
            Message.error(`连接失败，原因：${message.ChatLogContent.text_content}`);
            allowReconnect = false;
        } else {
            allowReconnect = true;
            onMessageCb && onMessageCb(message);
            // console.log(`%c收到服务端消息[${msgType[message.ChatMessageType]}]====>`, 'color: red', message)
            heartCheck.reset().start();
        }
    };
    ws.onclose = function () {
        reconnect(failCb);
    };
    ws.onerror = function () {
        reconnect(failCb);
    };
}

export function onMessage(cb) {
    if (cb) onMessageCb = cb;
}

export function closeWs() {
    allowReconnect = false
    console.log(`%c手动关闭连接====>`,'color: red')
    ws.close()
}

export function sendMessage(message) {
    const { userInfo, customerInfo, userSource } = store.state.chat;
    message.Sender = customerInfo.Sender;
    message.SenderId = customerInfo.SenderId;
    message.Source = userSource;
    message.Receiver = userInfo.senderName || message.Receiver;
    message.ReceiverId = userInfo.senderId || message.ReceiverId;
    if (!message.ChatLogContent) message.ChatLogContent = {};
    if (!ws) return
    ws.send(JSON.stringify(message));
}

window.startMsgTask = (interval) => {
    let timer;
    if (timer) {
        clearInterval(timer);
        timer = null;
    }
    const { userInfo, customerInfo, userSource } = store.state.chat;
    const message = {
        ChatMessageType: 402,
        Sender: customerInfo.Sender,
        SenderId: customerInfo.SenderId,
        Source: userSource,
        Receiver: userInfo.senderName,
        ReceiverId: userInfo.senderId,
        ChatLogContent: {
            text_content: '这是一条客服回复消息'
        }
    }
    ws.send(JSON.stringify(message));
    timer = setInterval(() => {
        ws.send(JSON.stringify(message));
    }, interval);
}

// 重连
function reconnect(failCb) {
    if(!allowReconnect) return;
    if (reconnectNum >= 10) {
        Message({
            message: '连接失败，请重新进入客服系统',
            type: 'error'
        })
        failCb()
        return;
    }
    if (lockReconnect) return;
    lockReconnect = true;
    reconnectTimeout = setTimeout(() => {
        reconnectNum++;
        // console.log(`正在进行第${reconnectNum}次重连`)
        webSocketInit();
        lockReconnect = false;
    }, 2000);
}
